import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const PromoCodeUpdate = () => {

    const { promoId } = useParams();
    const [promoCode, setPromoCode] = useState({});
    // console.log(promoCode)

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    // promo code update handler
    const promoCodeUpdateHandler = () => {

        axios.patch(`https://dzinebackend.techsistltd.com/dashboard/promo_code/${promoId}/`, promoCode)
            .then(function (response) {
                // console.log(response)
                if (response.data.status) {
                    Toast.fire({
                        icon: 'success',
                        title: 'Promo Code Updated!'
                    })
                }
                else {
                    alert(response.data.msg)
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        fetch(`https://dzinebackend.techsistltd.com/dashboard/promo_code/${promoId}/`)
            .then(res => res.json())
            .then(data => setPromoCode(data.data))
    }, [promoId])

    return (
        <div className="container py-3 p-4 shadow ">
            <h2 className='py-3 fw-bolder text-secondary card-title'>Update Your PrmoCode</h2>

            <form className="row g-3">
                <div className="col-md-6">
                    <div className="form-floating ">
                        <input type="text" className="form-control" id="code" placeholder="code" required
                            value={promoCode.code || " "}
                            onChange={(e) => {
                                setPromoCode({ ...promoCode, code: e.target.value })
                            }} />
                        <label htmlFor="companyName">code</label>
                    </div>
                </div>

                <div className="col-6">
                    <div className="form-floating ">
                        <input type="text" className="form-control" id="amount" placeholder="Amount"
                            value={promoCode.amount || ""}
                            onChange={(e) => setPromoCode({ ...promoCode, amount: e.target.value })} />
                        <label htmlFor="amount">Amount</label>
                    </div>
                </div>

                <div className="col-4">
                    <div className="form-floating ">
                        <input type="text" className="form-control" id="promo_type" placeholder="Promo type"
                            disabled
                            value={promoCode.promo_type || ""}
                            onChange={(e) => setPromoCode({ ...promoCode, promo_type: e.target.value })} />
                        <label htmlFor="promo_type">Promo type</label>
                    </div>
                </div>

                <div className="col-4">
                    <div className="form-floating ">
                        <input type="date" className="form-control" id="start_date" placeholder="dd-mm-yyyy"
                            value={promoCode.start_date || ""}
                            onChange={(e) => setPromoCode({ ...promoCode, start_date: e.target.value })} />
                        <label htmlFor="start_date">Start Date</label>
                    </div>
                </div>

                <div className="col-4">
                    <div className="form-floating ">
                        <input type="date" className="form-control" id="end_date" placeholder="dd-mm-yyyy"
                            value={promoCode.end_date || ""}
                            onChange={(e) => setPromoCode({ ...promoCode, end_date: e.target.value })} />
                        <label htmlFor="end_date">End Date</label>
                    </div>
                </div>

                <div className="col-6">
                    <div className="form-floating ">
                        <input type="text" className="form-control" id="min_amount" placeholder="min_amount"
                            value={promoCode.minimum_purchase_amount || ""}
                            onChange={(e) => setPromoCode({ ...promoCode, minimum_purchase_amount: e.target.value })} />
                        <label htmlFor="min_amount">Min Amount</label>
                    </div>
                </div>

                <div className="col-6">
                    <div className="form-floating ">
                        <input type="text" className="form-control" id="max_amount" placeholder="max amount"
                            value={promoCode.max_amount || ""}
                            onChange={(e) => setPromoCode({ ...promoCode, max_amount: e.target.value })} />
                        <label htmlFor="max_amount">Max Amount</label>
                    </div>
                </div>






                <div className="col-12">
                    <button type="button" className="update-btn" onClick={promoCodeUpdateHandler}>Update</button>
                </div>
            </form>


        </div>
    );
};

export default PromoCodeUpdate;