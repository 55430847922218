import React from 'react';
import useDashboard from './../../../Hooks/useDashboard';
import companyLogo from "../../../images/logo/dzinebd.png";
import { useParams } from 'react-router';
import { useState, useEffect } from 'react';


const PromoCodeDetails = () => {
    const allDashboard = useDashboard();
    const { company, setCompany } = allDashboard;


    const { promoId } = useParams()
    const [promoDetails, setPromoDetails] = useState({})
    // console.log(companyDiscount)


    useEffect(() => {
        fetch(`https://dzinebackend.techsistltd.com/dashboard/promo_code/${promoId
            }/`)

            .then(res => res.json())
            .then(data => setPromoDetails(data.data))
    }, [promoId])

    return (
        <div className='container'>
            <div className="row py-3 mx-auto">
                <h2 className='py-3 fw-bolder text-secondary card-title'>Promo Details</h2>

                <div className=' border mx-auto  shadow-lg rounded p-5'>


                    <img className='img-fluid mx-auto d-block' src={promoDetails?.image} alt="" width={"100"} />

                    <div className='py-3'>
                        <h1 className=' fs-4   company-header'>Promo Code: <span className='company-names'>{promoDetails.code}</span></h1>
                        <h1 className=' fs-5  py-2 company-header'>Start Date: <span className='company-names'>{promoDetails.start_date}</span></h1>
                        <h2 className=' fs-5 py-2  '>End Date: <span className='company-name'>{promoDetails.end_date}</span></h2>
                        <h2 className=' fs-5 py-2  '>Type: <span className='company-name'>{promoDetails.promo_type}</span></h2>
                        <h2 className=' fs-5 py-2  '>Max Amount: <span className='company-name'>{promoDetails.max_amount}</span></h2>
                        <h2 className=' fs-5 py-2  '>Min Amount: <span className='company-name'>{promoDetails.minimum_purchase_amount}</span></h2>
                        <h2 className=' fs-5 py-2  '>Discount amount: <span className='company-name'>{promoDetails.amount}</span></h2>


                    </div>
                </div>


            </div>
        </div>
    );
};

export default PromoCodeDetails;