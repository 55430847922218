import React from 'react';
import cash from "../../../images/payment-img/cash.png";
import mandiri from "../../../images/payment-img/mandiri.svg";
import permatabank from "../../../images/payment-img/permetabank.svg";
import master_card from "../../../images/payment-img/master-card.svg";

const PaymentMethod = () => {
    return (
        <div className='bg-white p-3 mt-3'>
            <h3 className='order-process-all-title-txt'>Payment Method</h3>
            <div className="row py-3">
                <div className="col-12 col-sm-12 col-md-3 col-lg-3">

                    <div className="form-check order-process-sub-title-txt">
                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" defaultChecked />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                            Cash on delivery
                        </label>
                        <br />
                        <img src={cash} alt="" className='img-fluid m-2' width="60" />
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-3 col-lg-3">

                    <div className="form-check order-process-sub-title-txt">
                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" defaultChecked />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                            Mandiri Cicilan 0%
                        </label>
                        <br />
                        <img src={mandiri} alt="" className='img-fluid' height="100" width="100" />
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-3 col-lg-3">

                    <div className="form-check order-process-sub-title-txt">
                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" defaultChecked />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                            Bank Transfer
                        </label>
                        <br />
                        <img src={permatabank} alt="" className='img-fluid' height="100" width="100" />
                    </div>

                </div>
                <div className="col-12 col-sm-12 col-md-3 col-lg-3">

                    <div className="form-check order-process-sub-title-txt">
                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" defaultChecked />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                            Credit Card
                        </label>
                        <br />
                        <img src={master_card} alt="" className='img-fluid' height="100" width="100" />
                    </div>

                </div>
            </div>

            {/* <div className="row ">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">

                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" defaultChecked />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                            Credit Card Cicilan 0% (Danamon, UOB & Standard Chartered)
                        </label>
                        <br />
                        <img src={bni} alt="" className='img-fluid' height="100" width="100" />
                    </div>
                </div>

                <div className="col-12 col-sm-12 col-md-3 col-lg-3">

                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" defaultChecked />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                            Bank Transfer
                        </label>
                        <br />
                        <img src={permatabank} alt="" className='img-fluid' height="100" width="100" />
                    </div>

                </div>
                <div className="col-12 col-sm-12 col-md-3 col-lg-3">

                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" defaultChecked />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                            Credit Card
                        </label>
                        <br />
                        <img src={master_card} alt="" className='img-fluid' height="100" width="100" />
                    </div>

                </div>
            </div> */}
        </div>
    );
};

export default PaymentMethod;