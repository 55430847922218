import React from 'react';
import './VoucherCard.css';
import logo from "../../../images/logo/dzinebd.png";

const VoucherCard = ({ voucher }) => {

    const { code, amount, promo_type } = voucher;
    return (
        <div className="col">
            <div className="card h-100 voucher-card">
                <div className='voucher-card-body d-flex align-items-center'>
                    <div className='voucher-logo p-3'>
                        <img src={logo} className="img-fluid" alt="..." width="130" />
                    </div>
                    <div className="card-body voucher-card-in-body p-3 text-center">
                        <h6 className=" ">USE PROMO : </h6>
                        <h4 className=" ">{code}</h4>
                        <p className=" m-0">FOR BDT {amount}{promo_type === "PERCENTAGE" ? "%" : "TK"} OFF</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VoucherCard;