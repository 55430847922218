import React from "react";
import { Container } from "react-bootstrap";
import useFooterDetails from "../../Hooks/useFooterDetails";

const About = () => {
  const { description } = useFooterDetails();
  const data = description?.about_us;
  return (
    <Container className="py-5">
      <h1 className="footer-link-pages">About Us</h1>
      <hr className="hr" />
      <div
        dangerouslySetInnerHTML={{ __html: data }}
      />
    </Container>
  );
};

export default About;
