import React from 'react';
import useCart from '../../../Hooks/useCart';
import DetailsOrder from '../DetailsOrder/DetailsOrder';
import PaymentMethod from '../PaymentMethod/PaymentMethod';
import './Payment.css';

const Payment = () => {
    // cart context api
    const cartInfo = useCart();
    const { customerData, items, orderItems } = cartInfo;
    // console.log(items)


    return (
        <div className='container-fluid'>

            <div className="container mx-auto ">
                <div className="row">

                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <DetailsOrder></DetailsOrder>
                        {/* <div className='bg-white p-2'>
                            <div className='d-flex justify-content-between '>
                                <h3>Payment Detail</h3>
                                <h3 className='inline-block payment-time'>11:55:55</h3>
                            </div>
                            <p>Please make a payment according with the limit time specified, starting from now</p>
                        </div> */}

                    </div>

                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 ">


                        <div className='table-responsive bg-white '>
                            <h3 className='py-3 p-2 order-process-all-title-txt'>Order Details</h3>
                            <table className="table table-borderless">

                                <tbody>
                                    <tr>
                                        <td className="order-process-sub-title-txt ">Order Number</td>
                                        <td>
                                            <p className='fw-bolder m-0 order-process-sub-title-txt'>{items?.order_no}</p>
                                            <p className='text-secondary p-0 m-0 order-process-sub-title-txt'>Always remember Order Number for easy tracking</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="order-process-sub-title-txt">Date </td>
                                        <td className="order-process-sub-title-txt">{items?.created_at}</td>
                                    </tr>
                                    <tr>
                                        <td className="order-process-sub-title-txt">Total Items</td>
                                        <td className="order-process-sub-title-txt">{orderItems?.length}</td>
                                        {/* <td className="order-process-sub-title-txt">
                                            {
                                                orderItems?.map(item => <span>
                                                    {item?.product_details?.product_name} <br />
                                                    {item?.quantity} x BDT {item?.product_details?.product_price} <br />

                                                </span>)
                                            }
                                        </td> */}
                                    </tr>
                                    <tr>
                                        <td className="order-process-sub-title-txt">Name</td>
                                        <td>{customerData?.name}</td>
                                    </tr>
                                    <tr>
                                        <td className="order-process-sub-title-txt">Phone</td>
                                        <td>{customerData?.phone_number}</td>
                                    </tr>
                                    <tr>
                                        <td className="order-process-sub-title-txt">Email</td>
                                        <td className="order-process-sub-title-txt">{customerData?.email_address}</td>
                                    </tr>
                                    <tr>
                                        <td className="order-process-sub-title-txt">Shipping Address</td>
                                        <td className="order-process-sub-title-txt">{customerData?.shipping_address}</td>
                                    </tr>

                                </tbody>


                            </table>
                        </div>

                    </div>
                </div>
                <PaymentMethod></PaymentMethod>
            </div>

        </div>
    );
};

export default Payment;