import React, { useState } from 'react';
import './CartProduct.css';
import cartImage from '../../../images/cart-img.png';
import { FaTrashAlt } from "react-icons/fa";
import useCart from '../../../Hooks/useCart';

const CartProduct = ({ item, order_items_filter_fn }) => {
    // console.log(item)


    // cart context api
    const cartInfo = useCart();
    const { orderItems, setOrderItems } = cartInfo;
    // console.log(orderItems);

    const { product_name, product_price, product_total_price, product_size, product_image_url, product_discount_price, product_color, active_price } = item?.product_details;

    const [orderItemId, setOrderItemId] = useState();

    //product quantity state 
    const [count, setCount] = useState(item.quantity);

    //product price state 
    const [price, setPrice] = useState(product_price);

    // console.log(price)

    // product qnty update handler
    const updateProductQntyHandler = (pdt_id, qnty) => {

        let item = orderItems.find(item => item.id === pdt_id);
        item.quantity = qnty;
    }

    // product qnty update handler
    const updateProductPriceHandler = (pdt_id, price) => {

        let item = orderItems.find(item => item.id === pdt_id);
        item.product_price = price;
    }

    // console.log(orderItems);

    return (
        <>
            <div className="row align-items-center p-2">

                <div className="col-12 col-sm-12 col-md-6 col-lg-6 d-flex align-items-center ">
                    <img src={product_image_url} alt="" className=' cart-img' />
                    <div className='ms-2'>
                        <p className=' m-0 cards-title'>{product_name}</p>
                        {/* <p className='text-decoration-line-through text-muted m-0'>BDT 1,280.00</p> */}
                        {/* <p className='m-0 product-price-cart'>BDT {product_discount_price}</p> */}
                        <p className='m-0 product-price-cart'>Color: {product_color}</p>
                        <p className='m-0 product-price-cart'>Size: {product_size}</p>
                    </div>
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-end ">

                    <div className='d-flex justify-content-end py-4 '>
                        {/* decriment btn  */}
                        <input type="button" value="-" className='incre-decre-btn' onClick={() => {
                            setCount(count - 1);
                            updateProductQntyHandler(item.id, count - 1);
                            setPrice(active_price * (count - 1));
                            updateProductPriceHandler(item.id, active_price * (count - 1))
                        }}
                            disabled={count === 1} />

                        {/* product qnt  */}
                        <input type="button" value={count} className=' text-center border-0 bg-light fw-bolder cart-product-total-price' />

                        {/* increment btn  */}
                        <input type="button" value="+" className='incre-decre-btn' onClick={() => {
                            setCount(count + 1);
                            updateProductQntyHandler(item.id, count + 1);
                            setPrice(active_price * (count + 1));
                            updateProductPriceHandler(item.id, active_price * (count + 1))

                        }} />

                        <p className=' p-2 m-0 cart-product-total-price' >{active_price * count} </p>

                        <div className='border border-danger p-1  inline' onClick={() => {

                            order_items_filter_fn(item?.id)

                        }}>
                            <FaTrashAlt className='text-danger ' ></FaTrashAlt>
                        </div>
                    </div>
                </div>


            </div>
            <hr className='cart-hr' />
        </>
    );
};

export default CartProduct;