import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MdUpdate } from 'react-icons/md';
import { TiDelete } from 'react-icons/ti';
import { GrView } from 'react-icons/gr';

const Users = () => {

    const [users, setUsers] = useState([]);
    // console.log(users)

    useEffect(() => {
        fetch("https://dzinebackend.techsistltd.com/account/user_list/")
            .then(res => res.json())
            .then(data => setUsers(data.data))
    }, [])

    return (
        <div>
            {
                <div className="col table-responsive">

                    <table className="table  table-bordered  rounded shadow-sm  table-hover ">
                        <thead className='table-header'>
                            <tr className='text-center'>
                                <th className='py-3' scope="col" width="50">SL</th>
                                <th className='py-3' scope="col">Name</th>
                                <th className='py-3' scope="col">Email</th>
                                <th className='py-3' scope="col">NID</th>
                                <th className='py-3' scope="col">Role</th>
                                <th className='py-3' scope="col">Action</th>


                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {
                                users?.map((user, index) => <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{user?.staff_information?.name}</td>
                                    <td>{user?.email}</td>
                                    <td>{user?.staff_information?.nid}</td>

                                    <td>{user?.staff_information?.is_owner === "True" && "Owner."}
                                        {user?.staff_information?.is_manager === "True" && "Manager."}
                                        {user?.staff_information?.is_moderator === "True" && "Moderator."}
                                        {user?.staff_information?.is_dzine_staff === "True" && "Staff."}</td>

                                    <td className=' text-center'>

                                        {/* <Link to={`/dashboard/promoCodeDetails/${user.id}`}>
                                            <GrView className='text-success fs-5'></GrView>

                                        </Link> */}

                                        <Link to={`/dashboard/users/${user.id}`}>
                                            <MdUpdate className='mx-2 fs-4'></MdUpdate>
                                        </Link>

                                        {/* <button onClick={() => handleDeleteOrder(promoCode?.id)}><i className="fas fa-trash"></i></button> */}
                                        {/* <TiDelete className=' fs-3 text-danger' role={"button"}
                                            onClick={() => handleDeleteOrder(user?.id)}></TiDelete> */}
                                    </td>


                                </tr>)
                            }


                        </tbody>
                    </table>
                </div>
            }
        </div>
    );
};

export default Users;