import React from 'react';
import { useParams } from 'react-router';
import { useState, useEffect } from 'react';
import useDashboard from '../../../Hooks/useDashboard';


const DiscountDetails = () => {

    const { discountId } = useParams();
    const [companyDiscount, setCompanyDiscount] = useState({})
    // console.log(companyDiscount)

    useEffect(() => {
        fetch(`https://dzinebackend.techsistltd.com/dashboard/discount/${discountId
            }/`)

            .then(res => res.json())
            .then(data => setCompanyDiscount(data.data))
    }, [discountId])






    // const { id, image_url, title, description, schedule_type, discount_type, start_time, closing_time, start_date, end_date, amount
    // } = discount;


    // const { serviceId } = useParams()
    // useEffect(() => {
    //     fetch(`https://dzinebackend.techsistltd.com/dashboard/discount/${serviceId
    //         }`)
    //         .then((res) => res.json())
    //         .then((data) => setDiscountDetails(data.data))


    // }, []);
    return (
        <div className='container'>
            <div className="row py-3 mx-auto">
                <h2 className='py-3 fw-bolder text-secondary card-title'>Discount Details</h2>

                <div className=' border mx-auto  shadow-lg rounded p-5'>


                    <img className='img-fluid mx-auto d-block' src={companyDiscount.image_url} alt="" width={"100"} />

                    <div className='py-3'>
                        <h1 className=' fs-4   company-header'>Discount title: <span className='company-names'>{companyDiscount.title}</span></h1>
                        <h2 className=' fs-5 py-2  '>Discount description: <span className='company-name'>{companyDiscount.description}</span></h2>
                        <h3 className=' fs-5   company-header'>Schedule type: <span className='company-others'>{companyDiscount.schedule_type}</span></h3>
                        <h3 className=' fs-5 py-2  company-header'>Dicount type: <span className='company-others'>{companyDiscount.discount_type}</span></h3>
                        <h4 className=' fs-5   company-header'>Start time: <span className=' py-2 company-others'>{companyDiscount.start_time}</span></h4>
                        <h4 className=' fs-5  company-header py-2'>Closing Time: <span className='company-others'>{companyDiscount.closing_time}</span></h4>
                        <h4 className='  fs-5  company-header'>Start date: <span className='company-others'>{companyDiscount.start_date}</span></h4>
                        <h4 className='fs-5 py-2   company-header'>end date: <span className='company-others '>{companyDiscount.end_date}</span></h4>
                        <h4 className='fs-5   company-header'>amount: <span className='company-others'>{companyDiscount.amount}</span> </h4>
                    </div>
                </div>


            </div>
        </div>
    );
};

export default DiscountDetails;