import axios from 'axios';
import React from 'react';
import Swal from 'sweetalert2';
import useCart from '../../../Hooks/useCart';
import DetailsOrder from '../DetailsOrder/DetailsOrder';
import './Checkout.css';

const Checkout = ({ formData, setFormData }) => {

    // sweetalert toast for order
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    // cart context api
    const cartInfo = useCart();
    const { items, customerData, setCustomerData } = cartInfo;
    // console.log(customerData)


    const customerInfoHandler = () => {

        axios.post('https://dzinebackend.techsistltd.com/account/customer_info/', customerData)
            .then(function (response) {
                // console.log(response);
                // console.log(response.data?.data?.customer_info);
                if (response.data.status) {
                    setCustomerData(customerData);
                    Toast.fire({
                        icon: 'success',
                        title: 'Customer Info Updated!'
                    })
                }
                else {
                    alert(response?.data?.msg)
                }

            })
            .catch(function (error) {
                console.log(error);
            });

    }

    return (
        <div className='container-fluid'>

            <div className="container mx-auto ">
                <div className="row">

                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 pb-3">
                        <DetailsOrder></DetailsOrder>

                    </div>

                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 bg-white">
                        <h3 className='py-2 order-process-all-title-txt'>Billing Address</h3>
                        <form className="row g-3" >


                            <div className="col-md-12">
                                <label htmlFor="name" className="form-label order-process-sub-title-txt">Fullname</label>
                                <input type="name" className="form-control" id="name" placeholder='Your Full Name'
                                    required
                                    value={customerData?.name}
                                    onChange={(e) => {
                                        setCustomerData({ ...customerData, name: e.target.value });
                                    }} />
                            </div>

                            <div className="col-md-12">
                                <label htmlFor="email" className="form-label order-process-sub-title-txt">Email Address</label>
                                <input type="email" required className="form-control" id="email" placeholder="Email id"
                                    value={customerData?.email_address}
                                    onChange={(e) => {
                                        setCustomerData({ ...customerData, email_address: e.target.value });
                                    }} />
                            </div>

                            <div className="col-md-12">
                                <label htmlFor="phoneNumber" className="form-label order-process-sub-title-txt">Phone Number</label>
                                <input type="text" required className="form-control" id="phoneNumber" placeholder="Ex: +88-01600000000" value={customerData?.phone_number}
                                    onChange={(e) => {
                                        setCustomerData({ ...customerData, phone_number: e.target.value });
                                    }} />
                            </div>

                            <div className="col-12">
                                <label htmlFor="shippingAddress" className="form-label order-process-sub-title-txt">Shipping Address</label>
                                <input type="text" required className="form-control" id="shippingAddress" placeholder="Ex: Sector-01 , plot-122 , Mohammadpur "
                                    value={customerData?.shipping_address}
                                    onChange={(e) => {
                                        setCustomerData({ ...customerData, shipping_address: e.target.value });
                                    }} />
                            </div>

                            <div className="col-md-12">
                                <label htmlFor="country" className="form-label order-process-sub-title-txt" >Country</label>
                                <select id="country" required className="form-select" onChange={(e) => {
                                    setCustomerData({ ...customerData, country: e.target.value });
                                }}>
                                    <option selected >Choose...</option>
                                    <option >Bangladesh</option>

                                </select>
                            </div>

                            <div className="col-md-7">
                                <label htmlFor="city" className="form-label order-process-sub-title-txt" >Area</label>
                                <select id="city" required className="form-select" onChange={(e) => {
                                    setCustomerData({ ...customerData, city: e.target.value });
                                }}>
                                    <option selected >Choose...</option>
                                    <option >Dhaka</option>
                                    <option >Chattogram</option>
                                    <option >Khulna</option>
                                    <option >Barisal </option>
                                    <option >Mymensingh</option>
                                    <option >Rajshahi</option>
                                    <option >Rangpur</option>
                                    <option >Sylhet</option>

                                </select>
                            </div>




                            <div className="col-md-5">
                                <label htmlFor="zipCode" className="form-label">Zip</label>
                                <input type="text" className="form-control" id="zipCode"
                                    placeholder='1200'
                                    value={customerData?.zip_code}
                                    onChange={(e) => {
                                        setCustomerData({ ...customerData, zip_code: e.target.value });
                                    }} />
                            </div>



                            {/* <div className="col-12 d-flex justify-content-between py-3 bg-light">
                                <button type="submit" className="cnt-shoppig-btn">Continue Shopping</button>
                                <button type="submit" className=" place-order-btn">Place My Order</button>
                            </div> */}

                        </form>
                        <button type="button" className='add-customer-info-btn' onClick={customerInfoHandler}>Send</button>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default Checkout;