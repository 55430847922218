import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

const DiscountCreate = () => {

    const { register, handleSubmit, reset } = useForm();

    const [selectSchedule, setSelectSchedule] = useState("Time_Wise");


    const onSubmit = (data) => {

        // console.log(data)

        const formData = new FormData();
        formData.append("title", data?.title);
        formData.append("is_popup", data?.is_popup);
        formData.append("is_slider", data?.is_slider);
        formData.append("schedule_type", data?.schedule_type);
        formData.append("start_time", data?.start_time);
        formData.append("closing_time", data?.closing_time);
        formData.append("start_date", data?.start_date);
        formData.append("end_date", data?.end_date);
        formData.append("discount_type", data?.discount_type);
        formData.append("amount", data?.amount);
        formData.append("image", data?.image[0]);

        axios.post('https://dzinebackend.techsistltd.com/dashboard/discount/', formData)
            .then((response) => {
                // console.log(response);
                if (response.data.status) {
                    alert(response.data.msg);
                    reset();
                }
                else {
                    alert(response.data.msg);
                }


            })
            .catch((error) => console.error("Error -> ", error));

    };

    return (
        <div className="container py-3 p-4 shadow ">
            <h2 className='py-3 fw-bolder text-secondary card-title'>Add Discount</h2>

            <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>

                <div className="col-md-6">
                    <div className="form-floating ">
                        <input {...register("title")} placeholder="title" className="form-control" id="code" required />
                        <label htmlFor="title">Title</label>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-floating ">
                        <input type="file" {...register("image")} className="form-control" id="image" />
                        <label htmlFor="image">Discount Image</label>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-floating">
                        <select {...register("is_popup")} className="form-select" id="is_popup" aria-label="Floating label select example" >

                            <option value="TRUE">Show</option>
                            <option value="FALSE">Not Showing</option>

                        </select>
                        <label htmlFor="is_popup">PopUp</label>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-floating">
                        <select {...register("is_slider")} className="form-select" id="is_slider" aria-label="Floating label select example" >

                            <option value="TRUE">Show</option>
                            <option value="FALSE">Not Showing</option>

                        </select>
                        <label htmlFor="is_slider">For Slider</label>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="form-floating">
                        <select {...register("schedule_type")} className="form-select" id="schedule_type" aria-label="Floating label select example" onChange={(e) => setSelectSchedule(e.target.value)}>


                            <option value="Time_Wise">Time Wise</option>
                            <option value="Date_Wise">Date Wise</option>

                        </select>
                        <label htmlFor="schedule_type">Schedule Type</label>
                    </div>
                </div>

                {
                    selectSchedule === "Time_Wise" ? <>
                        <div className="col-md-4">
                            <div className="form-floating ">
                                <input type="time" {...register("start_time")} placeholder="start_time" className="form-control" id="start_time" required />
                                <label htmlFor="start_time">Start Time</label>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="form-floating ">
                                <input type="time" {...register("closing_time")} placeholder="closing_time" className="form-control" id="closing_time" required />
                                <label htmlFor="closing_time">Closing Time</label>
                            </div>
                        </div></> : <>
                        <div className="col-md-4">
                            <div className="form-floating ">
                                <input type={"date"} {...register("start_date")} placeholder="start_date" className="form-control" id="start_date" required />
                                <label htmlFor="start_date">Start Date</label>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-floating ">
                                <input type={"date"} {...register("end_date")} placeholder="end_date" className="form-control" id="end_date" required />
                                <label htmlFor="end_date">End Date</label>
                            </div>
                        </div>
                    </>
                }



                <div className="col-6 pb-3">
                    <div className='form-floating'>
                        <select {...register("discount_type")} id="discount_type" className="form-control">
                            <option value="PERCENTAGE">Percentage %</option>
                            <option value="AMOUNT">Amount</option>

                        </select>
                        <label htmlFor="discount_type">Discount Type</label>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-floating ">
                        <input type="number" {...register("amount")} placeholder="amount" className="form-control" id="amount" required />
                        <label htmlFor="amount">Discount Amount</label>
                    </div>
                </div>



                <div className="col-12">
                    <button type="submit" className="update-btn" >Submit</button>
                </div>
            </form>


        </div>
    );
};

export default DiscountCreate;