import React, { useState } from 'react';
import Navigation from '../Shared/Navigation/Navigation';
import './OrderProcess.css';
import Checkout from "./Checkout/Checkout";
import Payment from "./Payment/Payment";
import Confirmation from "./Confirmation/Confirmation";
import Swal from 'sweetalert2'
import axios from 'axios';
import useCart from '../../Hooks/useCart';
// import withReactContent from 'sweetalert2-react-content'


const OrderProcess = () => {



    // cart context api
    const cartInfo = useCart();
    const { items, setItems, setOrderItems, customerData } = cartInfo;
    // console.log(customerData)

    const [page, setPage] = useState(0);
    const FormTitles = ["Chekout", "Payment", "Confirmation"];


    const confirmOrderHandler = () => {
        axios.post('https://dzinebackend.techsistltd.com/dashboard/confirm_order/', {
            order_id: items.id
        })
            .then(function (response) {
                // console.log(response.data);
                if (response?.data?.status) {
                    setItems([]);
                    setOrderItems([]);
                    Swal.fire(
                        'Order Placed Successfull!!',
                        'Thank You!',
                        'success'
                    ).then(function () {
                        window.location = "/home";
                    });
                }
                else {
                    Swal.fire({
                        html: response?.data?.msg,
                        confirmButtonColor: '#d4b16c'

                    }
                    )

                }

            })
            .catch(function (error) {
                console.log(error);
            });

    }

    //console.log(formData);
    // const MySwal = withReactContent(Swal)

    const PageDisplay = () => {
        if (page === 0) {
            return <Checkout ></Checkout>
        } else if (page === 1) {
            return <Payment ></Payment>
        }
        else if (page === 2) {
            return <Confirmation ></Confirmation>
        }
    };

    return (
        <>
            {/* <Navigation></Navigation> */}
            <div className='container-fluid py-3'>

                <div className="form container mx-auto">
                    <div className="progressbar mx-auto">
                        <div
                            style={{ width: page === 0 ? "33.3%" : page === 1 ? "66.6%" : "100%" }}
                            className="d-flex"
                        >
                        </div>
                    </div>
                    <div className="form-container">
                        <div className="header">
                            {/* <h1>{FormTitles[page]}</h1> */}
                        </div>
                        <div className="body">{PageDisplay()}</div>
                        <div className=" text-end py-3">
                            <button
                                className='control-btn'
                                disabled={page === 0}
                                onClick={() => {
                                    setPage((currPage) => currPage - 1);
                                }}
                            >
                                Prev
                            </button>
                            <button
                                className='control-btn'
                                onClick={() => {
                                    if (page === FormTitles.length - 1) {

                                        confirmOrderHandler();

                                    } else {
                                        setPage((currPage) => currPage + 1);
                                    }
                                }}
                            >
                                {page === FormTitles.length - 1 ? "Submit" : "Next"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrderProcess;