import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import Footers from "../Shared/Footers/Footers";
import Navigation from "../Shared/Navigation/Navigation";
import "./Voucher.css";
import VoucherCard from "./VoucherCard/VoucherCard";

const Voucher = () => {
  const [vouchers, setVouchers] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fetch("https://dzinebackend.techsistltd.com/dashboard/available_promo_code_list/")
      .then((res) => res.json())
      .then((data) => {
        setVouchers(data.data);
        setError(data.error.error_details);
      })
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <div className="d-flex justify-content-center py-5">
      <RotatingLines
        strokeColor="#d4b16c"
        strokeWidth="3"
        animationDuration="0.75"
        width="50"
        visible={true}
      />
    </div>
  }
  return (
    <>
      {/* <Navigation></Navigation> */}
      <div className="container-fluid">
        <div className="container py-5 mx-auto ">

          {<div className="text-center text-success fs-4">{error}</div>}
          <div className="row row-cols-1 row-cols-md-1 row-cols-lg-2 g-4">
            {vouchers?.map((voucher) => (
              <VoucherCard key={voucher.id} voucher={voucher}></VoucherCard>
            ))}
          </div>
        </div>
      </div>
      {/* <Footers></Footers> */}
    </>
  );
};

export default Voucher;
