import React, { createContext, useState } from 'react';

export const CartContext = createContext();

const CartProvider = ({ children }) => {
    const [items, setItems] = useState([]);
    const [orderItems, setOrderItems] = useState([]);
    const [finalOrderData, setFinalOrderData] = useState([]);
    const [prices, setPrices] = useState({});
    const [customerData, setCustomerData] = useState({});


    return (
        <CartContext.Provider value={{ items, setItems, orderItems, setOrderItems, finalOrderData, setFinalOrderData, prices, setPrices, customerData, setCustomerData }}>
            {children}
        </CartContext.Provider>
    );
};

export default CartProvider;