import React from "react";
import "./BillingShippingPolicy.css";
import { Container } from "react-bootstrap";
import useFooterDetails from "../../Hooks/useFooterDetails";
const BillingShippingPolicy = () => {
  const { description } = useFooterDetails();
  const data = description?.billing_and_shipping_policy;
  return (
    <Container className="py-5">
      <h1 className="footer-link-pages">Billing and shipping policy</h1>
      <hr className="hr" />
      <div
        // className="py-5 footer-link-pages-description"
        dangerouslySetInnerHTML={{ __html: data }}
      />
    </Container>
  );
};

export default BillingShippingPolicy;
