import React, { useEffect } from 'react';
import './RelatedProducts.css';
import RelatedProductCard from './RelatedProductCard';
import ProductCard from '../../components/ProductCard/ProductCard';

const RelatedProducts = ({ product }) => {

    const { product_list } = product;
    // console.log(product_list)

    return (


        <div className='container-fluid py-5'>
            <div className='container'>
                <h2 className='related-pdt-header'>Related Products</h2>
                <hr className='horizontal-related ' style={{ height: "3px" }} />



                <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 g-4 py-3">
                    {
                        product_list?.map(product => <ProductCard
                            product={product}
                            key={product?.id}

                        ></ProductCard>)
                    }
                </div>


            </div>

        </div>
    );
};

export default RelatedProducts;
