import React, { useEffect, useState } from "react";
import { Container, Card, Accordion } from "react-bootstrap";
import JobCircular from "./JobCircular";
import "./JoinUs.css";
const JoinUs = () => {
  const baseURL = "http://192.168.20.248:8000";
  const [career, setCareer] = useState({});
  useEffect(() => {
    fetch(`${baseURL}/dashboard/job_circular_list/`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setCareer(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <Container>
      <h2 className="title mt-3">Join Us</h2>
      <hr className="hr" />

      {/* Accordion Section */}
      <div className="my-5">
        {/* <h1 className="title display-5 text-center my-5">Open Positions</h1> */}
        {career?.data?.length === 0 ? (
          <Card style={{ width: "auto" }}>
            <Card.Body>
              <Card.Text className="text-center">
                Sorry, there are no positions available at this time.
              </Card.Text>
            </Card.Body>
          </Card>
        ) : (
          <Accordion className="bg-transparent">
            {career?.data?.map((job) => (
              <JobCircular key={job?.id} job={job} baseURL={baseURL} />
            ))}
          </Accordion>
        )}
      </div>
    </Container>
  );
};

export default JoinUs;
