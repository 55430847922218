import React, { useState } from "react";
import "./JobCircular.css";
import {
  Card,
  Accordion,
  Button,
  useAccordionButton,
  Form,
  ToastContainer,
  Toast,
} from "react-bootstrap";
import { useForm } from "react-hook-form";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log("totally custom!")
  );
  return (
    <Button type="button" onClick={decoratedOnClick}>
      {children}
    </Button>
  );
}

const JobCircular = ({ job, baseURL }) => {
  const [show, setShow] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const job_id = job?.id;
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("file", data.file[0]);
    formData.append("body", data.body);
    formData.append("job_circular", job_id);
    fetch(`${baseURL}/dashboard/drop_cv/`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => alert(data?.msg))
      .catch((error) => console.error("Error -> ", error));
    reset();
  };
  return (
    <div id="job-circular">
      {/* job circular start */}
      <Accordion.Item
        eventKey={job?.id}
        className="job-circular bg-transparent"
      >
        <Accordion.Header>
          <Card className="border-0 job-card bg-transparent">
            <Card.Body>
              <Card.Title as="h4" className="open-position-title">
                {job?.title}
              </Card.Title>
            </Card.Body>
          </Card>
        </Accordion.Header>
        <Accordion.Body>
          <div dangerouslySetInnerHTML={{ __html: job?.description }} />
          <Accordion className="apply-accordion">
            <Card className="border-0 bg-transparent">
              <Card.Header className="apply-card border-0 text-center">
                <CustomToggle eventKey={job?.title}>Apply Now</CustomToggle>
              </Card.Header>
              <Accordion.Collapse eventKey={job?.title}>
                <Card.Body>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Enter your Name"
                        {...register("name", {
                          required: true,
                        })}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="email"
                        placeholder="Enter your Email"
                        {...register("email", {
                          required: true,
                        })}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Enter your Phone Number"
                        {...register("phone", {
                          required: true,
                        })}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        as="textarea"
                        rows={3}
                        type="text"
                        placeholder="Write about yourself"
                        {...register("body")}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        type="file"
                        accept=".jpeg, .pdf"
                        {...register("file" === null ? "" : "file", {
                          required: true,
                        })}
                      />
                    </Form.Group>
                    <div className="text-center">
                      <Button variant="primary" type="submit">
                        Submit
                      </Button>
                    </div>
                  </Form>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Accordion.Body>
      </Accordion.Item>
      {/* job circular end */}
      <ToastContainer className="p-3" position="middle-center">
        <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
          <Toast.Header closeButton={false}>
            <strong className="me-auto">Success!</strong>
          </Toast.Header>
          <Toast.Body>
            Congrats! Your application has been submitted successfully.
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export default JobCircular;
