import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const CatagoryUpdate = () => {
    const { catId } = useParams();
    const [catagory, setCatagory] = useState({});
    // console.log(catagory)

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const catagoryUpdateHandler = () => {
        const formData = new FormData();
        formData.append("title", catagory.title)
        formData.append("image", catagory.image[0])

        axios.patch(`https://dzinebackend.techsistltd.com/dashboard/category/${catId}/`, formData)
            .then(function (response) {
                if (response.data.status) {
                    Toast.fire({
                        icon: 'success',
                        title: 'Catagory Updated!'
                    })
                }
                else {
                    alert(response.data.msg)
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        fetch(`https://dzinebackend.techsistltd.com/dashboard/category/${catId}/`)
            .then(res => res.json())
            .then(data => setCatagory(data.data))
    }, [catId])

    return (
        <div className="container py-3 p-4 shadow ">
            <h2 className='py-3 fw-bolder text-secondary'>Product Category Update</h2>

            <form className="row g-3">
                <div className="col-md-6">
                    <div className="form-floating ">
                        <input type="text" className="form-control" id="title" placeholder="title" required
                            value={catagory.title || " "}
                            onChange={(e) => {
                                setCatagory({ ...catagory, title: e.target.value })
                            }} />
                        <label htmlFor="title">Title</label>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-floating ">
                        <input type="file" className="form-control" id="image" placeholder="image" required
                            onChange={(e) => {
                                setCatagory({ ...catagory, image: e.target.files })
                            }}
                        />
                        <label htmlFor="image">Image</label>
                    </div>
                </div>


                <div className="col-12">
                    <button type="button" className="update-btn" onClick={catagoryUpdateHandler}>Update</button>
                </div>
            </form>


        </div>
    );
};

export default CatagoryUpdate;