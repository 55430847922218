import axios from 'axios';
import React from 'react';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, ButtonGroup, Modal, Table, ToggleButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { TiDelete } from 'react-icons/ti';
import { GrView } from 'react-icons/gr';
import { MdUpdate } from 'react-icons/md';
import { IoIosAddCircle } from 'react-icons/io';
import { FiEdit } from 'react-icons/fi';
import ProductColor from '../ProductColor/ProductColor';
import Swal from 'sweetalert2';

const Product = () => {

    const [product, setProduct] = useState([])
    const [productId, setProductId] = useState(0)
    // console.log(product)

    //add color 
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const colorAddHandler = (pdt_id) => {
        setProductId(pdt_id);
        setShow(true);
    }


    // sweetalert toast for order
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    // color delete handler
    const colorDeleteHandler = (id) => {
        axios.delete(`https://dzinebackend.techsistltd.com/dashboard/product_color/${id}/`)
            .then(res => {

                if (res.data.status) {

                    Toast.fire({
                        icon: 'success',
                        title: `${res.data.msg}`
                    })
                }
                else {
                    alert(res.data.msg);
                }
            })
            .catch(error => console.log(error))
    }

    // delete product from list 
    const handleDeleteOrder = id => {
        if (confirm("Are you sure want to delete this product?")) {//eslint-disable-line
            const url = `https://dzinebackend.techsistltd.com/dashboard/product/${id}/`;
            fetch(url, {
                method: 'DELETE'
            })
                .then(res => res.json())
                .then(data => {
                    if (data) {
                        alert('deleted successfully')
                        const remainingProduct = product?.filter(pdt => pdt.id !== id);
                        setProduct(remainingProduct);
                    }
                    else {
                        alert(data.msg);
                    }
                })
        }
    }

    useEffect(() => {
        fetch("https://dzinebackend.techsistltd.com/dashboard/product/")
            .then((res) => res.json())
            .then((data) => setProduct(data.data))


    }, []);


    return (

        <>

            <div className='container'>
                <div className="row py-3">

                    <div className="col">
                        <Link to="/dashboard/productCreate"> <button type="button" className=" create-button">+ Product Create

                        </button></Link>
                        <br />
                        <br />

                        <Table className="table   table-bordered border-primary rounded  shadow-sm  table-hover">
                            <thead className='table-header'>
                                <tr>

                                    <th className='py-3' scope="col">Product ID</th>
                                    <th className='py-3' scope="col">Product Category</th>
                                    <th className='py-3' scope="col">Product Title</th>
                                    <th className='py-3' scope="col">Product Image</th>
                                    <th className='py-3' scope="col">Product Color</th>

                                    <th className='py-3' scope="col">Price</th>
                                    <th className='py-3' scope="col">Discount Title</th>
                                    <th className='py-3' scope="col">Action</th>

                                </tr>
                            </thead>
                            {product?.map((pdt, index) => (

                                <tbody key={index} className="">
                                    {/* {console.log(pdt.product_color)} */}
                                    <tr>
                                        <td className='align-middle'>{pdt?.id}</td>
                                        <td className='align-middle'>{pdt?.category_details?.title}</td>
                                        <td className='align-middle'>{pdt?.title}</td>
                                        <td className='align-middle'><img className='img-fluid' src={pdt?.image_url} alt="" width={"100"} /></td>

                                        <td className='align-middle'>
                                            {
                                                pdt?.product_color?.map((pdt_size, index) => {


                                                    return (
                                                        <>
                                                            <div className='d-flex align-items-center my-1 ' key={pdt_size?.id}>
                                                                <div>
                                                                    <img src={pdt_size?.image_url} alt="" width={"50"} className="p-1 border m-1" onClick={() => colorDeleteHandler(pdt_size.id)} data-bs-toggle="tooltip" data-bs-placement="top" title="Delete color & size" role={"button"} />

                                                                </div>
                                                                <ButtonGroup className="">
                                                                    {pdt_size?.product_size?.map((radio, index) => (
                                                                        <ToggleButton

                                                                            key={radio.id}
                                                                            id={`radio-${radio.id}`}
                                                                            type="radio"
                                                                            variant="outline-secondary"
                                                                            name="radio"
                                                                            value={radio.title}

                                                                            onChange={(e) => {

                                                                            }
                                                                            }
                                                                        >
                                                                            {radio.title}-
                                                                            {radio.available_quantity}
                                                                        </ToggleButton>
                                                                    ))}
                                                                </ButtonGroup>

                                                            </div>

                                                        </>

                                                    )

                                                })
                                            }

                                        </td>

                                        <td className='align-middle'>{pdt?.price}</td>
                                        <td className='align-middle'>{pdt?.discount}</td>

                                        <td className='align-middle'>
                                            {/* <Link to={`/dashboard/productDetailss /${pdt.id}`}>

                                            </Link> */}
                                            <IoIosAddCircle className='text-success mx-2 fs-4 ' data-bs-toggle="tooltip" data-bs-placement="top" title="Add color & size" role={"button"} onClick={() => colorAddHandler(pdt.id)}>

                                            </IoIosAddCircle>

                                            <Link to={`/dashboard/productUpdate/${pdt.slug}`}>
                                                <FiEdit className='mx-2 fs-4' data-bs-toggle="tooltip" data-bs-placement="top" title="Update product"></FiEdit>
                                            </Link>
                                            <Link to={`/dashboard/productDetailss/${pdt.id}`}>
                                                <GrView className='text-success fs-5' data-bs-toggle="tooltip" data-bs-placement="top" title="Product Details"></GrView>
                                            </Link>
                                            <TiDelete className=' fs-3 text-danger' data-bs-toggle="tooltip" data-bs-placement="top" title="Product Delete" role={"button"}
                                                onClick={() => handleDeleteOrder(pdt?.id)}></TiDelete>

                                        </td>

                                    </tr>
                                </tbody>
                            ))}
                        </Table>

                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><h2 className='py-3 fw-bolder text-secondary card-title'>Add Color</h2></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ProductColor productId={productId} ></ProductColor>
                </Modal.Body>

            </Modal >
        </>
    );
};

export default Product;