import React, { useEffect, useState } from "react";
import "./Footers.css";
import logos from "../../../images/logo/dzinebd.png";
import { Link } from "react-router-dom";
import useAuth from "../../../Hooks/useAuth";

// Footer second part starting
const Footers = () => {
  const [footerInfo, setFooterInfo] = useState({});

  // auth context
  const authInfo = useAuth();
  const { user } = authInfo;

  useEffect(() => {
    fetch("https://dzinebackend.techsistltd.com/dashboard/company_details/")
      .then((res) => res.json())
      .then((data) => setFooterInfo(data.data));
  }, []);

  return (
    <div className="container-fluid footers">
      <div className="container mx-auto">
        <div className="row pt-5">
          <div className="col-12 col-sm-12 col-md-6 col-lg-3 text-white py-3 ">
            <img
              className="brand-img img-fluid mb-3"
              src={footerInfo?.logo_url}
              alt=""
            />

            <h5 className="footer-title-1">Address</h5>
            <p className="footer-title-sub">{footerInfo?.address}</p>
            <br />

            <h5 className="footer-title-1">Office Hour</h5>
            <p className="footer-title-sub">{footerInfo?.office_hour}</p>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-4 text-white py-3">
            <h2 className="footer-title-2">Get in touch</h2>
            <hr className="footer-hr" />
            <table className="table table-borderless text-white">
              <tbody>
                <tr>
                  <td className="p-0 pb-2 footer-title-sub-1">Phone</td>
                  <td className="p-0 footer-title-sub ">{footerInfo?.phone}</td>
                </tr>
                <tr>
                  <td className="p-0 pb-2 footer-title-sub-1">
                    Service <br></br>Center{" "}
                  </td>
                  <td className="p-0 footer-title-sub">
                    {footerInfo?.service_phone}
                  </td>
                </tr>
                <tr>
                  <td className="p-0 pb-2 footer-title-sub-1">
                    Customer<br></br> Service
                  </td>
                  <td className="p-0 footer-title-sub">
                    {footerInfo?.customer_service_phone}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="py-3 d-flex align-items-center">
              <a
                className="social-anchor"
                href={footerInfo.facebook_url}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <i className="fab fa-facebook social-icon"></i>
              </a>
              <a
                className="social-anchor"
                href={footerInfo.instagram_url}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <i className="fab fa-instagram social-icon"></i>
              </a>
              <a
                className="social-anchor"
                href={footerInfo.twitter_url}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-twitter social-icon"></i>
              </a>
              <a
                className="social-anchor"
                href={footerInfo.linkedin_url}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-linkedin-in social-icon"></i>
              </a>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-3 text-white py-3">
            <h2 className="footer-title-2">Useful Link</h2>
            <hr className="footer-hr" />
            <div>
              <h6 className="footer-title-sub ">
                <Link className="text-decoration-none text-dark" to="/">
                  Waranty & complaints
                </Link>
              </h6>
              <h6 className="footer-title-sub">
                <Link className="text-decoration-none text-dark" to="/">
                  Order & shipping
                </Link>
              </h6>
              <h6 className="footer-title-sub">
                <Link className="text-decoration-none text-dark" to="/">
                  Tracking order
                </Link>
              </h6>
              <h6 className="footer-title-sub">
                <Link className="text-decoration-none text-dark" to="/">
                  About us
                </Link>
              </h6>
              <h6 className="footer-title-sub">
                <Link className="text-decoration-none text-dark" to="/">
                  Exchange
                </Link>
              </h6>
              <h6 className="footer-title-sub">
                <Link className="text-decoration-none text-dark" to="/">
                  Terms
                </Link>
              </h6>
              <h6 className="footer-title-sub">
                <Link className="text-decoration-none text-dark" to="/">
                  Faq
                </Link>
              </h6>
              <h6 className="footer-title-sub">
                <Link className="text-decoration-none text-dark" to="/login">
                  official
                </Link>
              </h6>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-2 text-white py-3">
            <h2 className="footer-title-2">Campaign</h2>
            <hr className="footer-hr" />
            <div>
              <h6 className="footer-title-sub ">
                <Link className="text-decoration-none text-dark" to="/">
                  Waranty & complaints
                </Link>
              </h6>
              <h6 className="footer-title-sub">
                <Link className="text-decoration-none text-dark" to="/">
                  Order & shipping
                </Link>
              </h6>
              <h6 className="footer-title-sub">
                <Link className="text-decoration-none text-dark" to="/">
                  Tracking order
                </Link>
              </h6>
              <h6 className="footer-title-sub">
                <Link className="text-decoration-none text-dark" to="/">
                  About us
                </Link>
              </h6>
              <h6 className="footer-title-sub">
                <Link className="text-decoration-none text-dark" to="/">
                  Exchange
                </Link>
              </h6>
              <h6 className="footer-title-sub">
                <Link className="text-decoration-none text-dark" to="/">
                  Terms
                </Link>
              </h6>
              <h6 className="footer-title-sub">
                <Link className="text-decoration-none text-dark" to="/">
                  Faq
                </Link>
              </h6>
            </div>
          </div>
        </div>

        <p className="text-dark pb-4 p-0 m-0 text-center">
          Copyright © 2022. Techsist Limited. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footers;
