import React, { useState } from "react";
import "./CustomizeUs.css";
import { Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";

const CustomizeUs = () => {

  const [sending, setSending] = useState(false);
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = data => {
    // console.log(data)
    setSending(true);
    const formData = new FormData();
    formData.append('design_file', data.design_file[0]);
    formData.append('recording_file', data.recording_file[0]);
    formData.append('name', data.name);
    formData.append('email_address', data.email_address);
    formData.append('phone_number', data.phone_number);
    formData.append('shipping_address', data.shipping_address);
    formData.append('country', data.country);
    formData.append('city', data.city);
    formData.append('zip_code', data.zip_code);

    axios.post('https://dzinebackend.techsistltd.com/dashboard/customize_us_product_order/', formData)
      .then(function (response) {
        if (response.data.status) {
          Swal.fire(
            `${response.data.msg}`,
            'Welcome Dzine Family',
            'success'
          )
          reset();
        }
        else {
          alert(response.data.msg)
        }

      })
      .catch(function (error) {
        console.log(error);
      }).finally(() => setSending(false));
  }

  if (sending) {
    return <div className="d-flex justify-content-center align-items-center py-5 min-vh-100">
      <RotatingLines
        strokeColor="#d4b16c"
        strokeWidth="3"
        animationDuration="0.75"
        width="50"
        visible={true}
      />
    </div>
  }
  return (
    <Container>
      <h2 className="title mt-4">Customize Us</h2>
      <hr className='horizontal-related ' style={{ height: "3px" }} />
      {/* Form Start */}
      <form className="row mt-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="choose-file p-3">
          <small className="py-2">Upload your custom design file here</small>
          <input
            type="file"
            className="file-form-control bg-transparent border-0 text-white"
            id=""
            {...register("design_file", { required: true })}
          />
        </div>
        <div className="choose-file p-3 mt-3">
          <small className="py-2">Upload your Voice recording here</small>
          <input
            type="file"
            className="file-form-control bg-transparent border-0 text-white"
            id=""
            {...register("recording_file", { required: true })}
          />
        </div>
        <div className="col-md-6 mt-3">
          <div className=" col-md-12 pb-3">
            <label htmlFor="name" className="form-label title-txt">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Ex: S M Aliuzzaman"
              {...register("name", { required: true })}
            />
          </div>

          <div className=" col-md-12 pb-3">
            <label htmlFor="email" className="form-label title-txt">
              Email Address
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Ex: aliuzzaman.uiu026@gmail.com"
              {...register("email_address", { required: true })}
            />
          </div>

          <div className=" col-md-12 pb-3">
            <label htmlFor="number" className="form-label title-txt">
              Phone Number
            </label>
            <input
              type="text"
              className="form-control"
              id="number"
              placeholder="Ex: 01788651774"
              {...register("phone_number", { required: true })}
            />
          </div>
          <div className=" col-md-12 pb-3">
            <label htmlFor="address" className="form-label title-txt">
              Address
            </label>
            <input
              type="text"
              className="form-control"
              id="address"
              placeholder="Ex: Sector-01 , plot-122 , Mohammadpur "
              {...register("shipping_address", { required: true })}
            />
          </div>
        </div>

        {/* 2nd column */}
        <div className="col-md-6 mt-3">
          <div className=" col-md-12 pb-3">
            <label htmlFor="country" className="form-label title-txt">
              Country
            </label>
            <select name="country" id="country" className="form-select" {...register("country", { required: true })}>
              <option selected>Choose...</option>
              <option value="bangladesh">Bangladesh</option>
            </select>
          </div>
          <div className=" col-md-12 pb-3 d-flex">
            <div className="col-md-6 me-2">
              <label htmlFor="city" className="form-label title-txt">
                City
              </label>
              <select name="city" id="city" className="form-select" {...register("city", { required: true })}>
                <option selected>Choose...</option>
                <option value="dhaka">Dhaka</option>
              </select>
            </div>
            <div className="col-md-6">
              <label htmlFor="zip-code" className="form-label title-txt">
                Zip Code
              </label>
              <input
                type="text"
                id="zip-code"
                className="form-control"
                placeholder="Ex: 7400"
                {...register("zip_code", { required: true })}
              />
            </div>
          </div>
        </div>
        <div className="col-12">
          <input type="submit" className="appointment-submit-button my-5" value="Submit" />
        </div>
      </form>
    </Container>
  );
};

export default CustomizeUs;
