import React from "react";
import { Container } from "react-bootstrap";
import useFooterDetails from "../../Hooks/useFooterDetails";
const StoreLocation = () => {
  const { description } = useFooterDetails();
  const data = description?.store_location;
  return (
    <Container className="py-5">
      <h1 className="footer-link-pages">Store Location</h1>
      <hr className="hr" />
      <div
        dangerouslySetInnerHTML={{ __html: data }}
      />
    </Container>
  );
};

export default StoreLocation;
