import React from 'react';
import { useForm } from 'react-hook-form';
import Footers from '../../Shared/Footers/Footers';
import loginImg from '../../../images/registration.svg';
import Navigation from '../../Shared/Navigation/Navigation';
import { Link } from 'react-router-dom';
import { MdEmail } from "react-icons/md";
import axios from 'axios';
import Swal from 'sweetalert2';

const Register = () => {
    const { register, handleSubmit, reset } = useForm();
    const onSubmit = data => {

        // console.log(data)
        axios.post('https://dzinebackend.techsistltd.com/account/register/', data)
            .then(function (response) {
                // console.log(response);
                if (response.data.status) {
                    Swal.fire(
                        `${response.data.msg}`,
                        'Welcome Dzine Family',
                        'success'
                    )
                    reset();
                }
                else {
                    alert(response.data.msg)

                }

            })
            .catch(function (error) {
                console.log(error);
            });
    };

    return (
        <>
            <Navigation></Navigation>
            <div className="container-fluid bg-light">
                <div className="container mx-auto py-3">
                    <div className="row d-flex justify-content-center align-items-center p-3">

                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 border  p-5 shadow-sm bg-white">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <input {...register("username")} placeholder="Your Name" className="form-control order-process-sub-title-txt " required />
                                <br />
                                <input {...register("email")} placeholder="Your E-mail" className="form-control order-process-sub-title-txt " required />
                                <br />
                                <input type="password" {...register("password")} placeholder="Password" className="form-control order-process-sub-title-txt " required />
                                <br />
                                {/* <input type="password" {...register("confirmPassword")} placeholder="Confirm Password" className="form-control  order-process-sub-title-txt" required />
                                <br /> */}
                                <input type="submit" value="Register" className='login-register-btn w-100' />
                            </form>
                            <p className='py-3'><span className='text-secondaryorder-process-sub-title-txt'>Already have an account?</span><Link to="/login" className='text-decoration-none '>Login</Link></p>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-center">
                            <img src={loginImg} alt="" className='img-fluid login-img ' />
                        </div>
                    </div>
                </div>
            </div>
            <Footers></Footers>
        </>
    );
};

export default Register;