import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const OrderDetailsView = () => {
    const { orderId } = useParams();
    //  order details
    const [detailsOrder, setOrderDetail] = useState([]);
    // console.log(detailsOrder)

    //is loading state
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        // setIsLoading(true);
        fetch(`https://dzinebackend.techsistltd.com/dashboard/order_detail/${orderId}/`)
            .then(res => res.json())
            .then(data => setOrderDetail(data.data))
        // .finally(() => setIsLoading(false))
    }, [])
    return (
        <div className='container'>

            <div className="row py-3 mx-auto">
                <div className=' border mx-auto  shadow-lg rounded p-3'>

                    <div className='py-3'>
                        <div className="row">

                            <div className="col-12 col-sm-12 col-md-7 col-lg-8">
                                <div className='table-responsive'>
                                    <table className="table bg-white">
                                        <thead>
                                            <tr>
                                                <td className='py-3 p-2 order-process-all-title-txt'>Order Info</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='order-process-sub-title-txt'>Order No</td>
                                                <td className='order-process-sub-title-txt'>{detailsOrder?.order_no}</td>
                                            </tr>
                                            <tr>
                                                <td className='order-process-sub-title-txt'>Ordered Items </td>
                                                <td className='order-process-sub-title-txt'>

                                                    {detailsOrder?.ordered_items?.map(item => {
                                                        // return item?.map(singleItem => console.log(singleItem))
                                                        return <>
                                                            <span>{item?.product_details.product_name}</span>,
                                                            <span>{item?.product_details.product_size}</span>,
                                                            <span>Qty: {item?.quantity}</span><br />
                                                        </>
                                                    })}

                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='order-process-sub-title-txt'>Customer Name</td>
                                                <td className='order-process-sub-title-txt'>{detailsOrder?.customer_info?.name}</td>
                                            </tr>
                                            <tr>
                                                <td className='order-process-sub-title-txt'>Phone Number</td>
                                                <td className='order-process-sub-title-txt'>{detailsOrder?.customer_info?.phone_number}</td>
                                            </tr>
                                            <tr>
                                                <td className='order-process-sub-title-txt'>Email</td>
                                                <td className='order-process-sub-title-txt'>{detailsOrder?.customer_info?.email_address}</td>
                                            </tr>
                                            <tr>
                                                <td className='order-process-sub-title-txt'>City</td>
                                                <td className='order-process-sub-title-txt'>{detailsOrder?.customer_info?.city}</td>
                                            </tr>
                                            <tr>
                                                <td className='order-process-sub-title-txt'>Zip</td>
                                                <td className='order-process-sub-title-txt'>{detailsOrder?.customer_info?.zip_code}</td>
                                            </tr>
                                            <tr >
                                                <td className=' order-process-sub-title-txt'>Shipping Address</td>
                                                <td className=' order-process-sub-title-txt'>{detailsOrder?.customer_info?.shipping_address}</td>
                                            </tr>



                                        </tbody>

                                        {/* <tfoot>
                                            <tr >
                                                <td className='fw-bolder order-process-sub-title-txt'>Shipping Address</td>
                                                <td className='fw-bolder order-process-sub-title-txt'>{detailsOrder?.customer_info?.shipping_address}</td>
                                            </tr>
                                        </tfoot> */}

                                    </table>

                                    {/* <input type="text" name="" id="" placeholder='Discount Code' className=' promo-input  p-2 ' onChange={(e) => setPromoTxt(e.target.value)} />
                                    <button className='add-customer-info-btn' onClick={promoCodeHandler}>Apply</button> */}
                                </div>
                            </div>

                            <div className="col-12 col-sm-12 col-md-5 col-lg-4">
                                <div className='table-responsive'>
                                    <table className="table bg-white">
                                        <thead>
                                            <tr>
                                                <td className='py-3 p-2 order-process-all-title-txt'>Detail Order</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='order-process-sub-title-txt'>Product Total Price</td>
                                                <td className='order-process-sub-title-txt'>{detailsOrder?.price?.product_total_price}</td>
                                            </tr>

                                            <tr>
                                                <td className='order-process-sub-title-txt'>Discount Amount</td>
                                                <td className='order-process-sub-title-txt'>{detailsOrder?.price?.discount_amount}</td>
                                            </tr>
                                            <tr>
                                                <td className='order-process-sub-title-txt'>Shipping Cost </td>
                                                <td className='order-process-sub-title-txt'>{detailsOrder?.price?.delivery_charge}</td>
                                            </tr>
                                            <tr>
                                                <td className='order-process-sub-title-txt'>Packaging</td>
                                                <td className='order-process-sub-title-txt'>{detailsOrder?.price?.packaging_price}</td>
                                            </tr>

                                        </tbody>

                                        <tfoot>
                                            <tr >
                                                <td className='fw-bolder order-process-sub-title-txt'>Payable Amount</td>
                                                <td className='fw-bolder order-process-sub-title-txt'>{detailsOrder?.price?.payable_amount}</td>
                                            </tr>
                                        </tfoot>

                                    </table>

                                    {/* <input type="text" name="" id="" placeholder='Discount Code' className=' promo-input  p-2 ' onChange={(e) => setPromoTxt(e.target.value)} />
                                    <button className='add-customer-info-btn' onClick={promoCodeHandler}>Apply</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default OrderDetailsView;