import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { Link } from "react-router-dom";
import ProductCard from "../../ProductCard/ProductCard";
import TailorMe from "../TailorMe";
import "./TailorMeDetails.css";
const TailorMeDetails = () => {
  const [tailorMe, setTailorMe] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // console.log(deals)
  useEffect(() => {
    setIsLoading(true);
    fetch("https://dzinebackend.techsistltd.com/dashboard/tailor_me_product_list/")
      .then((res) => res.json())
      // .then((data) => console.log(data));
      .then((data) => setTailorMe(data.data))
      .finally(() => setIsLoading(false));
  }, []);


  if (isLoading) {
    return (
      <div className="d-flex justify-content-center py-5">
        <RotatingLines
          strokeColor="#d4b16c"
          strokeWidth="3"
          animationDuration="0.75"
          width="50"
          visible={true}
        />
      </div>
    );

  };
  return (
    <div className="container py-3 mx-auto">
      <section className="d-flex justify-content-between align-items-center">
        <h2 className="monthly ">Tailor Me</h2>
        <Link to="/tailorMeAll" className="text-decoration-none">
          <h5 className="view-all">View All</h5>
        </Link>
      </section>

      <hr className='horizontal-related ' style={{ height: "3px" }} />
      <div className="row row-cols-2 row-cols-md-2 row-cols-lg-4 g-3 py-3">

        {tailorMe?.slice(0, 4)?.map((product) => (
          <ProductCard product={product} key={product.id}></ProductCard>
        ))}
      </div>
    </div>
  );
};

export default TailorMeDetails;
