import React from "react";
import "./PrivacyPolicy.css";
import { Container } from "react-bootstrap";
import useFooterDetails from "../../Hooks/useFooterDetails";
const PrivacyPolicy = () => {
  const { description } = useFooterDetails();
  const data = description?.privacy_policy;
  return (
    <Container className="py-5">
      <h1 className="footer-link-pages">Privacy Policy</h1>
      <hr className="hr" />
      <div
        // className="py-5 footer-link-pages-description"
        dangerouslySetInnerHTML={{ __html: data }}
      />
    </Container>
  );
};

export default PrivacyPolicy;
