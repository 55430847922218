import React from 'react';
import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
const ProductCreate = () => {
    const navigate = useNavigate();

    const [promoImage, setPromoImage] = useState("");

    const [catagory, setCatagory] = useState([]);
    const [discount, setDiscount] = useState([]);


    const handleChange = (file) => {
        setPromoImage(file[0]);
    }

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const { register, handleSubmit, reset } = useForm();
    const onSubmit = (data) => {
        // console.log(data)

        const formData = new FormData();
        formData.append("title", data?.title);
        formData.append("code", data?.code);
        formData.append("category", data?.category);
        formData.append("price", data?.price);
        formData.append("description", data?.description);
        formData.append("discount", data?.discount);
        formData.append("image", data?.image[0]);

        axios.post('https://dzinebackend.techsistltd.com/dashboard/product/', formData)
            .then(res => {
                console.log(res)
                if (res.data.status) {
                    Toast.fire({
                        icon: 'success',
                        title: 'Product Added!'
                    })

                    reset();
                    navigate("/dashboard/product")
                }
                else {
                    alert(res.data.msg);
                }
            })
    }

    useEffect(() => {
        fetch("https://dzinebackend.techsistltd.com/dashboard/category/")
            .then(res => res.json())
            .then(data => setCatagory(data?.data))
    }, [])

    useEffect(() => {
        fetch("https://dzinebackend.techsistltd.com/dashboard/discount/")
            .then(res => res.json())
            .then(data => setDiscount(data?.data))
    }, [])

    return (
        <div className="add-service py-3 ">
            <h2 className='py-3 fw-bolder text-secondary card-title'>Add New Product</h2>

            <form className='input-type border shadow  row p-5 g-3 ' onSubmit={handleSubmit(onSubmit)}>

                <div className="col-6 ">
                    <div className="form-floating">
                        <input {...register("title")} placeholder="Product Title" className="form-control" id="title" />
                        <label htmlFor="Title">Product Title</label>
                    </div>

                </div>

                <div className="col-6">
                    <div className="form-floating">

                        <input {...register("code")} placeholder="Product Code" className='form-control' id='code' />
                        <label htmlFor="code">Product Code</label>
                    </div>
                </div>

                <div className="col-6 ">
                    <div className="form-floating">

                        <select {...register("category")} className="form-select" id="category" aria-label="Floating label select example">
                            {
                                catagory?.map((cat => <option value={cat.id}>{cat.title}</option>))
                            }

                        </select>
                        <label htmlFor="category">Category</label>
                    </div>

                </div>

                <div className="col-6">
                    <div className="form-floating">
                        <input type="number" {...register("price")} placeholder="Product Price" className='form-control' id='price' />
                        <label htmlFor="price">Price</label>
                    </div>

                </div>

                <div className="col-6">
                    <div className="form-floating">
                        <input type="text" {...register("description")} placeholder="Product Description" className='form-control' id='Description' />
                        <label htmlFor="Description">Description</label>
                    </div>

                </div>



                <div className="col-6 ">
                    <div className="form-floating">

                        <select {...register("discount")} className="form-select" id="discount" aria-label="Floating label select example">
                            {
                                discount?.map((cat => <option value={cat.id}>{cat.title}</option>))
                            }

                        </select>
                        <label htmlFor="discount">Discount Title</label>
                    </div>

                </div>

                <div className="col-6">
                    <div className="form-floating">
                        <input className='form-control' accept='image' type="file" {...register("image")} />
                        <label htmlFor="image">Product Image</label>
                    </div>
                </div>

                <div className="col-12">
                    <input className='update-btn text-white border-0' type="submit" />
                </div>

            </form>
        </div>
    );
};

export default ProductCreate;