import React, { useState } from 'react';
import './Cart.css';
import useCart from '../../Hooks/useCart';
import CartProduct from './CartProduct/CartProduct';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';

const Cart = () => {

    let navigate = useNavigate();

    // cart context api
    const cartInfo = useCart();
    const { items, setItems, orderItems, setOrderItems, prices, setPrices } = cartInfo;
    // console.log(orderItems);

    //  remove order item from cart
    const order_items_filter_fn = (order_pdt_id) => {
        const remainingItems = orderItems?.filter(pdt => order_pdt_id !== pdt.id)
        setOrderItems(remainingItems);
    }


    // sweetalert toast for order
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const updateOrderHandler = () => {

        const url = "https://dzinebackend.techsistltd.com/dashboard/update_order_item/";
        axios.post(url, orderItems)
            .then(function (response) {
                // console.log(response);
                setItems(response?.data?.data)
                setPrices(response?.data?.data?.price);
                setOrderItems(response?.data?.data?.ordered_items);
                navigate("/orderProcess");

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <>
            {
                orderItems?.length === 0 ? <p className='cart-empty-msg'>Your Cart is Empty!!!</p> :
                    < div className="py-2">
                        <div className="cart-pdt-total">
                            {
                                orderItems?.map(item => <CartProduct
                                    key={item?.id}
                                    item={item}
                                    order_items_filter_fn={order_items_filter_fn}
                                ></CartProduct>)
                            }
                        </div>

                        <div className="  ">
                            <button className='checkout-btn w-100' data-dismiss="modal" data-bs-dismiss="modal" onClick={updateOrderHandler} >Checkout</button>
                            {/* <Link to="/orderProcess">
                                <button type="button" className="checkout-btn w-100" data-dismiss="modal" >Checkout</button>
                            </Link> */}

                        </div>
                    </div>
            }
        </>
    );
};

export default Cart;