import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./TailorMeNowSize.css";
import image from "../../../components/images/tailorMeSize.png";
import { Link, useParams } from "react-router-dom";
import useTailorMeNowData from "../../../Hooks/useTailorMeNowData";
import { RotatingLines } from "react-loader-spinner";

const TailorMeNowSize = () => {

  const { tailor_me_now_size_slug } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [measurementSize, setMeasurementSize] = useState({});
  // console.log(measurementSize);

  const [sizeImage, setSizeImage] = useState({});
  // console.log(sizeImage);

  // context api for tailor now data passing 
  const { userInfo, setUserInfo } = useTailorMeNowData();
  // console.log(userInfo?.product_measurement_info);

  // console.log(sizeImage);
  const remainingSizeImg = (id) => {
    const remainImg = measurementSize?.tailor_me_now_size?.find((tailor_size) => tailor_size?.id === id);
    return setSizeImage(remainImg);
  }


  //update mesurement data 
  const measurmentChangeHandler = (e, id) => {
    const { value } = e.target;
    // console.log(value);
    // console.log(userInfo);

    if (userInfo?.product_measurement_info?.length === 0) {
      setUserInfo({
        ...userInfo, product_measurement_info: [...userInfo.product_measurement_info, {
          id: id, measurement: value
        }
        ]
      })

    } else if (userInfo?.product_measurement_info?.length > 0) {

      const remain = userInfo?.product_measurement_info?.find((pdt_m) => pdt_m.id === id);
      if (remain?.id === id) {
        return remain.measurement = value;
      } else {
        return setUserInfo({
          ...userInfo, product_measurement_info: [...userInfo.product_measurement_info, {
            id: id, measurement: value
          }
          ]
        })
      }


    }

  }

  useEffect(() => {
    setIsLoading(true);
    fetch(`https://dzinebackend.techsistltd.com/dashboard/tailor_me_now_product_details/${tailor_me_now_size_slug}/`)
      .then((res) => res.json())
      // .then((data) => console.log(data.data))
      .then((data) => {
        setMeasurementSize(data.data)
        setSizeImage(data.data)
      })
      .finally(() => setIsLoading(false));
  }, [tailor_me_now_size_slug])

  if (isLoading) {
    return <div className="d-flex justify-content-center py-5">
      <RotatingLines
        strokeColor="#d4b16c"
        strokeWidth="3"
        animationDuration="0.75"
        width="50"
        visible={true}
      />
    </div>
  }
  return (
    <Container>
      <h2 className="title mt-3">Tailor Me Now</h2>
      <hr className='horizontal-related ' style={{ height: "3px" }} />

      <Row className="py-5">
        <Col md={6}>

          <section className="section-border p-3">
            <img src={sizeImage?.image_url} alt="" className="img-fluid d-block m-auto" />
            {/* <img src={sizeImage?.image_url} alt="" className="img-fluid w-100" /> */}
            {/* <div className="row pt-3">
              <div className="col-md-4 my-2 my-sm-2 my-md-2 my-lg-0">
                <img src={sizeImage?.image_url} alt="" className="img-fluid" width={"100"} />
              </div>
              <div className="col-md-4 my-2 my-sm-2 my-md-2 my-lg-0">
                <img src={sizeImage?.image_url} alt="" className="img-fluid" width={"100"} />
              </div>
              <div className="col-md-4 my-2 my-sm-2 my-md-2 my-lg-0">
                <img src={sizeImage?.image_url} alt="" className="img-fluid" width={"100"} />
              </div>
            </div> */}
          </section>
        </Col>
        <Col md={4}>
          {measurementSize?.tailor_me_now_size?.map((tailor_me_size, index) => <section className="p-3 p-sm-3 p-md-0 p-lg-0" key={tailor_me_size.id}>
            <section className="row section-border p-3 mb-0 mb-sm-0 mb-md-3 mb-lg-3"
              key={tailor_me_size.id}>
              <div className="col-md-6">
                <input

                  type="button"
                  className="form-control-custom text-center bg-transparent my-2 my-sm-2 my-md-2 my-lg-0"
                  value={tailor_me_size?.measurement_title}
                  onClick={() => remainingSizeImg(tailor_me_size?.id)}
                />
              </div>
              <div className="col-md-6">
                <input
                  // value={userInfo?.product_measurement_info.find((measurement) => measurement.id === tailor_me_size.id)}
                  type="text"
                  className="form-control-custom text-center my-2 my-sm-2 my-md-2 my-lg-0"
                  placeholder="5"
                  onChange={(event) => {
                    // console.log(event.target.value);
                    measurmentChangeHandler(event, tailor_me_size?.id)
                    // setUserInfo({
                    //   ...userInfo, product_measurement_info: [...userInfo.product_measurement_info, {
                    //     id: tailor_me_size?.id, measurement: event.target.value
                    //   }
                    //   ]
                    // })

                  }}
                />
              </div>

            </section>
          </section>
          )}
          {/* {userInfo?.product_measurement_info.length === 0 ? (<Link to="/customerInformation" className="text-decoration-none">

            <button className="add-To-Cart-Btn-Card my-3 w-50 d-block m-auto" disabled>
              Next
            </button>
          </Link>) : (<Link to="/customerInformation" className="text-decoration-none">

            <button className="add-To-Cart-Btn-Card my-3 w-50 d-block m-auto">
              Next
            </button>
          </Link>)} */}
          <Link to="/customerInformation" className="text-decoration-none">

            <button className="add-To-Cart-Btn-Card my-3 w-50 d-block m-auto" disabled={userInfo?.product_measurement_info.length !== 4}>
              Next
            </button>

          </Link>

        </Col>
      </Row>
    </Container>
  );
};

export default TailorMeNowSize;
