import React, { useContext, useEffect, useState } from 'react';
import { RotatingLines, Triangle } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import { DzineContext } from '../../context/DzineProvider';
import useDzine from '../../Hooks/useDzine';
import DetailsContent from '../DetailsContent/DetailsContent';
import ProductImage from '../ProductImage/ProductImage';
import RelatedProducts from '../RelatedProducts/RelatedProducts';
import Footers from '../Shared/Footers/Footers';
import Navigation from '../Shared/Navigation/Navigation';
import Service from './../Service/Service';

const ProductDetails = () => {

    const { productId } = useParams();
    const [product, setProduct] = useState({});
    // console.log(product);
    const [productColor, setProductColor] = useState({});
    const [isLoading, setIsLoading] = useState(true);


    let url = `https://dzinebackend.techsistltd.com/dashboard/product_details/${productId}/`;

    useEffect(() => {
        setIsLoading(true);
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setProduct(data?.data);
                setProductColor(data?.data?.product_color)
            }).finally(() => setIsLoading(false))
    }, [url])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (isLoading) {
        return <div className="d-flex justify-content-center py-5">
            <RotatingLines
                strokeColor="#d4b16c"
                strokeWidth="3"
                animationDuration="0.75"
                width="50"
                visible={true}
            />
        </div>
    }
    return (
        <div>
            {/* <Navigation></Navigation> */}
            <Service key={product.id} product={product} productColor={productColor}></Service>

            <DetailsContent product={product}></DetailsContent>

            {/* <ProductImage></ProductImage> */}

            <RelatedProducts key={product.slug} product={product}></RelatedProducts>


            {/* <Footer></Footer> */}
            {/* <Footers></Footers> */}
        </div>
    );
};

export default ProductDetails;