import { useEffect, useState } from "react";

const useFooterDetails = () => {
  const baseURL = "https://dzinebackend.techsistltd.com/dashboard/footer_details/";
  const [description, setDescription] = useState({});
  //   console.log(description);
  useEffect(() => {
    fetch(`${baseURL}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setDescription(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return { description };
};
export default useFooterDetails;
