import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './WelcomeDzine.css';

const WelcomeDzine = () => {
    const [summary, setSummary] = useState({});
    // console.log(summary);

    useEffect(() => {
        fetch("https://dzinebackend.techsistltd.com/dashboard/all_report/")
            .then(res => res.json())
            .then(data => setSummary(data.data))
    }, [])


    return (
        <div className='welcome-dzine-container'>
            {/* <h1>Welcome To Dzine Bangladesh</h1> */}

            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 pb-5">
                <div className="col">
                    <div className="h-100 summary-card">
                        {/* <img src="..." className="card-img-top" alt="..." /> */}
                        <div className="card-body">
                            <h5 className="summary-card-title">Current Total Sell</h5>
                            <p className="">{summary?.current_month_total_sell}</p>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="h-100 summary-card">
                        {/* <img src="..." className="card-img-top" alt="..." /> */}
                        <div className="card-body">
                            <h5 className="summary-card-title">Last Month Sell</h5>
                            <p className="">{summary?.last_month_total_sell}</p>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="h-100 summary-card">
                        {/* <img src="..." className="card-img-top" alt="..." /> */}
                        <div className="card-body">
                            <h5 className="summary-card-title">Total running order</h5>
                            <p className="">{summary?.total_running_order}</p>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="h-100 summary-card">
                        {/* <img src="..." className="card-img-top" alt="..." /> */}
                        <div className="card-body">
                            <h5 className="summary-card-title">Payable Amount</h5>
                            <p className="">{summary?.total_payable_amount}</p>
                        </div>
                    </div>
                </div>

            </div>

            {
                summary?.running_order_list?.length === 0 ? <div className="alert alert-primary text-center" role="alert">
                    No Order Available
                </div> : <div className="col table-responsive">

                    <table className="table  table-bordered  rounded shadow-sm  table-hover ">
                        <thead className='table-header'>
                            <tr className='text-center'>
                                <th className='py-3' scope="col" width="50">SL</th>
                                <th className='py-3' scope="col">Order No</th>
                                <th className='py-3' scope="col">Customer Name</th>
                                <th className='py-3' scope="col">Payable Amount</th>
                                <th className='py-3' scope="col">Order Date</th>
                                {/* <th className='py-3' scope="col">Action</th> */}


                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {
                                summary?.running_order_list?.map((ordr, index) => <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{ordr?.order_no}</td>
                                    <td>{ordr?.customer_info?.name}</td>
                                    <td>{ordr?.payable_amount}</td>
                                    <td>{ordr?.created_at}</td>
                                    {/* <td>
                                        <Link to={`/dashboard/orderDetailsView/${ordr.id}`}>
                                            <GrView className='text-success fs-5'></GrView>

                                        </Link>
                                    </td> */}

                                </tr>)
                            }


                        </tbody>
                    </table>
                </div>
            }
        </div>
    );
};

export default WelcomeDzine;