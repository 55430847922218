import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { MdUpdate } from 'react-icons/md';
import { TiDelete } from 'react-icons/ti';
import { GrView } from 'react-icons/gr';


const Catagory = () => {
    const [catagory, setCatagory] = useState([]);

    useEffect(() => {
        fetch('https://dzinebackend.techsistltd.com/dashboard/category/')
            .then((res) => res.json())
            .then((data) => setCatagory(data.data))

    }, [setCatagory]);


    const handleDeleteOrder = id => {


        const url = `https://dzinebackend.techsistltd.com/dashboard/category/${id}/`;
        fetch(url, {
            method: 'DELETE'
        })
            .then(res => res.json())
            .then(data => {
                if (data) {
                    alert('deleted successfully')
                    const remainingCatagory = catagory.filter(cat => cat.id !== id)
                    setCatagory(remainingCatagory);
                }
            })
    }


    return (
        <div className='container'>
            <div className="row my-5">
                <div className="col">
                    <Link to="/dashboard/catagoryCreate"> <button type="button" className=" create-button">+ Category Create

                    </button>
                        <br />
                        <br />

                    </Link>

                    <Table className="table   table-bordered border-primary rounded  shadow-sm  table-hover">
                        <thead className='table-header'>
                            <tr>

                                <th className='py-3' scope="col">Product Title</th>
                                <th className='py-3' scope="col">company </th>
                                <th className='py-3' scope="col">product image</th>

                                <th className='py-3' scope="col">Action</th>

                            </tr>
                        </thead>
                        {catagory?.map((catagorys, index) => (
                            <tbody key={catagorys.id}>
                                <tr>

                                    <td>{catagorys?.title}</td>
                                    <td>{catagorys?.company}</td>
                                    <td><img className='img-fluid' src={catagorys?.image_url} alt="" width={"100"} /></td>


                                    <td className=''>

                                        <Link to={`/dashboard/catagoryDetails/${catagorys.id}`}>
                                            <GrView className='text-success fs-5'></GrView>
                                        </Link>

                                        <Link to={`/dashboard/catagoryUpdate/${catagorys.id}`}>
                                            <MdUpdate className='mx-2 fs-4'></MdUpdate>
                                        </Link>

                                        <TiDelete className=' fs-3 text-danger' role={"button"}
                                            onClick={() => handleDeleteOrder(catagorys?.id)}></TiDelete>
                                    </td>





                                </tr>
                            </tbody>
                        ))}
                    </Table>

                </div>
            </div>
        </div>
    );
};

export default Catagory;