import React, { useEffect } from "react";
// import Service from '../Service/Service';
import Navigation from "../Shared/Navigation/Navigation";
import Banner from "./Banner/Banner";
// import Style from './../Style/Style';
import MonthlyDeals from "./MonthyDeals/MonthlyDeals";
// import RecentNews from './RecentNews/RecentNews';
// import MapleSeries from './MapleSeries/MapleSeries';
// import Testimonial from './../Testimonial/Testimonial';
// import Instragram from './../Instagram/Instragram';
// import Footer from './../Shared/Footer/Footer';
import Footers from "./../Shared/Footers/Footers";
import BannerOffer from "./BannerOffer/BannerOffer";
import ProductCatagories from "./ProductCatagories/ProductCatagories";
import useDzine from "../../Hooks/useDzine";
import FooterNew from "../Shared/FooterNew/FooterNew";
import TailorMeDetails from "../../components/TailorMe/TailorMeDetails/TailorMeDetails";

const Home = () => {
  return (
    <>
      {/* <Navigation></Navigation> */}
      {/* <Service></Service> */}
      {/* <Navigation></Navigation>   */}
      <BannerOffer></BannerOffer>
      {/* <Banner></Banner> */}
      {/* <Style></Style> */}
      <ProductCatagories></ProductCatagories>
      <MonthlyDeals></MonthlyDeals>
      <TailorMeDetails></TailorMeDetails>

      {/* <RecentNews></RecentNews> */}

      {/* <MapleSeries></MapleSeries> */}
      {/* <Testimonial></Testimonial> */}

      {/* <Instragram></Instragram> */}

      {/* <Footer></Footer> */}
      {/* <Footers></Footers> */}
      {/* <FooterNew></FooterNew> */}
    </>
  );
};

export default Home;
