import React from 'react';
import { useParams } from 'react-router';
import { useState, useEffect } from 'react';

const CatagoryDetails = () => {
    const { catId } = useParams()
    const [catagoryDetails, setCatagoryDetails] = useState({})
    // console.log(companyDiscount)


    useEffect(() => {
        fetch(`https://dzinebackend.techsistltd.com/dashboard/category/${catId
            }/`)

            .then(res => res.json())
            .then(data => setCatagoryDetails(data.data))
    }, [catId])



    return (
        <div className='container'>
            <div className="row py-3 mx-auto">


                <div className=' border mx-auto  shadow-lg rounded p-5'>


                    <img className='img-fluid mx-auto d-block' src={catagoryDetails.image_url} alt="" width={"100"} />

                    <div className='py-3'>
                        <h1 className=' fs-4   company-header'>Catagory title: <span className='company-names'>{catagoryDetails.title}</span></h1>
                        <h2 className=' fs-5 py-2  '>Company Name: <span className='company-name'>{catagoryDetails.company}</span></h2>

                    </div>
                </div>


            </div>
        </div>
    );
};

export default CatagoryDetails;