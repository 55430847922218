import React, { useEffect, useState } from "react";
import "./TailorMeAll.css";
import { Container } from "react-bootstrap";
import TailorMe from "../../components/TailorMe/TailorMe";
import ProductCard from "../../components/ProductCard/ProductCard";
import { Link } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";

const TailorMeAll = () => {
  const [tailorMe, setTailorMe] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortingPdt, setSortingPdt] = useState([]);


  const sortingHandler = (sortingTitle) => {

    setTailorMe([]);
    if (sortingTitle === "asc") {
      let low_high_pdt = tailorMe.slice().sort((a, b) => a.price - b.price);
      return setTailorMe(low_high_pdt);
    }
    else if (sortingTitle === "dsc") {
      let high_low_pdt = tailorMe.slice().sort((a, b) => b.price - a.price);
      return setTailorMe(high_low_pdt);
    }
    else if (sortingTitle === "all") {

      return setTailorMe(tailorMe);
    }
  }
  // console.log(deals)
  useEffect(() => {
    setIsLoading(true);
    fetch("https://dzinebackend.techsistltd.com/dashboard/tailor_me_product_list/")
      .then((res) => res.json())
      .then((data) => setTailorMe(data.data))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <div className="d-flex justify-content-center py-5">
      <RotatingLines
        strokeColor="#d4b16c"
        strokeWidth="3"
        animationDuration="0.75"
        width="50"
        visible={true}
      />
    </div>
  }
  return (
    <Container>
      <div className="row align-items-center pt-3">
        <div className="col-8 col-sm-8 col-md-9 col-lg-10">
          <nav aria-label="breadcrumb ">
            <ol className="breadcrumb products-breadcrumb m-0">
              <li className="breadcrumb-item"><Link to="/home" className='text-decoration-none text-dark'>Home</Link></li>
              <li className="breadcrumb-item"><Link to="/all-products" className='text-decoration-none text-dark'>Products</Link></li>

            </ol>
          </nav>
        </div>

        <div className="col-4 col-sm-4 col-md-3 col-lg-2">
          <select className="sort-by-select " aria-label="Default select example" onChange={(e) => sortingHandler(e.target.value)}>
            <option selected value="all" >Sort by</option>
            <option value="asc">Price: Low to High</option>
            <option value="dsc">Price: High to Low</option>

          </select>
        </div>


      </div>
      <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 g-3 py-3 mx-auto">

        {


          ((tailorMe?.length > 0) ? (tailorMe?.map(product => <ProductCard
            product={product}
            key={product.id}
          ></ProductCard>)) : <div className="alert alert-warning mx-auto text-center" role="alert">
            Products Not Available!!
          </div>)
        }
        {/* {tailorMe?.map((product) => (
          <ProductCard product={product} key={product.id}></ProductCard>
        ))} */}
      </div>
    </Container>
  );
};

export default TailorMeAll;
