import React, { createContext, useState } from 'react';

export const DashboardContext = createContext();

const DashboardProvider = ({ children }) => {

    const [company, setCompany] = useState({});
    const [discount, setDiscount] = useState([]);

    const updateCompany = (id, updatedEmployee) => {
        setCompany(company?.map((companys) => companys.id === id ? updatedEmployee : companys))
    }

    return (
        <DashboardContext.Provider value={{ company, setCompany, updateCompany, discount, setDiscount }}>
            {children}
        </DashboardContext.Provider>
    );
};

export default DashboardProvider;