import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const UserUpdate = () => {

    const { userId } = useParams();
    const [user, setUser] = useState({});
    // console.log(user)

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    // user update handler
    const userUpdateHandler = () => {

        const updateUser = {
            id: user?.id,
            name: user?.staff_information?.name,
            nid: user?.staff_information?.nid,
            is_owner: user?.staff_information?.is_owner,
            is_manager: user?.staff_information?.is_manager,
            is_moderator: user?.staff_information?.is_moderator,
            is_dzine_staff: user?.staff_information?.is_dzine_staff,

        }
        // console.log(updateUser)

        axios.patch(`https://dzinebackend.techsistltd.com/account/staff_information_update/`, updateUser)
            .then(function (response) {
                // console.log(response)
                if (response.data.status) {
                    Toast.fire({
                        icon: 'success',
                        title: 'User Updated!'
                    })
                }
                else {
                    alert(response.data.msg)
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        fetch(`https://dzinebackend.techsistltd.com/account/staff_information/${userId}/`)
            .then(res => res.json())
            .then(data => setUser(data.data))
    }, [userId])


    return (
        <div className="container py-3 p-4 shadow ">
            <h2 className='py-3 fw-bolder text-secondary card-title'>User Update Form</h2>

            <form className="row g-3">
                <div className="col-md-6">
                    <div className="form-floating ">
                        <input type="text" className="form-control" id="Name" placeholder="Name" required
                            value={user?.staff_information?.name || " "}
                            onChange={(e) => {
                                setUser({ ...user, staff_information: { ...user.staff_information, name: e.target.value } })
                            }} />
                        <label htmlFor="Name">Name</label>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-floating ">
                        <input type="text" className="form-control" id="username" placeholder="username" required
                            value={user?.username || " "}
                            disabled
                        />
                        <label htmlFor="username">User Name</label>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-floating ">
                        <input type="text" className="form-control" id="email" placeholder="email" required
                            value={user.email || " "}
                            disabled
                        />
                        <label htmlFor="email">Email</label>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-floating ">
                        <input type="text" className="form-control" id="nid" placeholder="nid" required
                            value={user?.staff_information?.nid || " "}
                            onChange={(e) => {
                                setUser({ ...user, staff_information: { ...user.staff_information, nid: e.target.value } })
                            }} />
                        <label htmlFor="nid">NID</label>
                    </div>
                </div>

                {/* owner role  */}
                <div className="col-md-3">
                    <div className="form-floating ">
                        <p>Please select role for : <span className='fw-bolder'>Owner</span></p>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="Owner" id="Owner1"

                                value="True"
                                checked={user?.staff_information?.is_owner === "True"}
                                onChange={(e) => {
                                    setUser({ ...user, staff_information: { ...user.staff_information, is_owner: e.target.value } })
                                }}

                            />
                            <label className="form-check-label" for="Owner1">
                                Yes
                            </label>
                        </div>

                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="Owner" id="Owner2"

                                value="False"
                                checked={user?.staff_information?.is_owner === "False"}
                                onChange={(e) => {
                                    setUser({ ...user, staff_information: { ...user.staff_information, is_owner: e.target.value } })
                                }}
                            />
                            <label className="form-check-label" for="Owner2">
                                No
                            </label>
                        </div>

                    </div>
                </div>

                {/* Manager role  */}
                <div className="col-md-3">
                    <div className="form-floating ">
                        <p>Please select role for : <span className='fw-bolder'>Manager</span></p>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="Manager" id="Manager1"

                                value="True"
                                checked={user?.staff_information?.is_manager === "True"}
                                onChange={(e) => {
                                    setUser({ ...user, staff_information: { ...user.staff_information, is_manager: e.target.value } })
                                }}

                            />
                            <label className="form-check-label" for="Manager1">
                                Yes
                            </label>
                        </div>

                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="Manager" id="Manager2"

                                value="False"
                                checked={user?.staff_information?.is_manager === "False"}
                                onChange={(e) => {
                                    setUser({ ...user, staff_information: { ...user.staff_information, is_manager: e.target.value } })
                                }}
                            />
                            <label className="form-check-label" for="Manager2">
                                No
                            </label>
                        </div>

                    </div>
                </div>

                {/* Moderator role  */}
                <div className="col-md-3">
                    <div className="form-floating ">
                        <p>Please select role for : <span className='fw-bolder'>Moderator</span></p>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="Moderator" id="Moderator1"

                                value="True"
                                checked={user?.staff_information?.is_moderator === "True"}
                                onChange={(e) => {
                                    setUser({ ...user, staff_information: { ...user.staff_information, is_moderator: e.target.value } })
                                }}

                            />
                            <label className="form-check-label" for="Moderator1">
                                Yes
                            </label>
                        </div>

                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="Moderator" id="Moderator2"

                                value="False"
                                checked={user?.staff_information?.is_moderator === "False"}
                                onChange={(e) => {
                                    setUser({ ...user, staff_information: { ...user.staff_information, is_moderator: e.target.value } })
                                }}
                            />
                            <label className="form-check-label" for="Moderator2">
                                No
                            </label>
                        </div>

                    </div>
                </div>

                {/* dzine_staff role  */}
                <div className="col-md-3">
                    <div className="form-floating ">
                        <p>Please select role for : <span className='fw-bolder'>Staff</span></p>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="Staff" id="Staff1"

                                value="True"
                                checked={user?.staff_information?.is_dzine_staff === "True"}
                                onChange={(e) => {
                                    setUser({ ...user, staff_information: { ...user.staff_information, is_dzine_staff: e.target.value } })
                                }}

                            />
                            <label className="form-check-label" for="Staff1">
                                Yes
                            </label>
                        </div>

                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="Staff" id="Staff2"

                                value="False"
                                checked={user?.staff_information?.is_dzine_staff === "False"}
                                onChange={(e) => {
                                    setUser({ ...user, staff_information: { ...user.staff_information, is_dzine_staff: e.target.value } })
                                }}
                            />
                            <label className="form-check-label" for="Staff2">
                                No
                            </label>
                        </div>

                    </div>
                </div>



                <div className="col-12">
                    <button type="button" className="update-btn" onClick={userUpdateHandler}>Update</button>
                </div>
            </form>


        </div>
    );
};

export default UserUpdate;