import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import ProductDetails from "./Pages/ProductDetails/ProductDetails";
import OrderProcess from "./Pages/OrderProcess/OrderProcess";
import Payment from "./Pages/OrderProcess/Payment/Payment";
import Confirmation from "./Pages/OrderProcess/Confirmation/Confirmation";
import Checkout from "./Pages/OrderProcess/Checkout/Checkout";
import ProductContainer from "./Pages/ProductContainer/ProductContainer";
import Login from "./Pages/Authentication/Login/Login";
import Register from "./Pages/Authentication/Register/Register";
import DiscountProductsContainer from "./Pages/DiscountProductsContainer/DiscountProductsContainer";
import AllProductCatagory from "./Pages/AllProductCatagory/AllProductCatagory";
import OnLoadPopUp from "./Pages/OnLoadPopUp/OnLoadPopUp";
import Voucher from "./Pages/Voucher/Voucher";
import SearchShow from "./Pages/SearchShow/SearchShow";
import DzineProvider from "./context/DzineProvider";
import DashboardDzine from "./Pages/Dashboard/DashboardDzine";
import CompanyCreate from "./Pages/Dashboard/CompanyCreate/CompanyCreate";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Company from "./Pages/Dashboard/Company/Company";
import PromoCode from "./Pages/Dashboard/PromoCode/PromoCode";
import Product from "./Pages/Dashboard/Product/Product";
import Review from "./Pages/Dashboard/Review/Review";
import CompanyDetails from "./Pages/Dashboard/CompanyDetails/CompanyDetails";
import CompanyUpdate from "./Pages/Dashboard/CompanyUpdate/CompanyUpdate";
import PromoCodeCreate from "./Pages/Dashboard/PromoCodeCreate/PromoCodeCreate";
import Catagory from "./Pages/Dashboard/Catagory/Catagory";
import Discount from "./Pages/Dashboard/Discount/Discount";
import CatagoryCreate from "./Pages/Dashboard/CatagoryCreate/CatagoryCreate";
import DiscountDetails from "./Pages/Dashboard/DiscountDetails/DiscountDetails";
import CatagoryDetails from "./Pages/Dashboard/CatagoryDetails/CatagoryDetails";
import ProductDetailss from "./Pages/Dashboard/ProductDetailss/ProductDetailss";
import PromoCodeDetails from "./Pages/Dashboard/PromoCodeDetails/PromoCodeDetails";
import ProductCreate from "./Pages/Dashboard/ProductCreate/ProductCreate";
import PromoCodeUpdate from "./Pages/Dashboard/PromoCodeUpdate/PromoCodeUpdate";
import ProductUpdate from "./Pages/Dashboard/ProductUpdate/ProductUpdate";
import CatagoryUpdate from "./Pages/Dashboard/CatagoryUpdate/CatagoryUpdate";
import DiscountUpdate from "./Pages/Dashboard/DiscountUpdate/DiscountUpdate";
import DiscountCreate from "./Pages/Dashboard/DiscountCreate/DiscountCreate";
import WelcomeDzine from "./Pages/Dashboard/WelcomeDzine/WelcomeDzine";
import CurrentOrder from "./Pages/Dashboard/Order/CurrentOrder/CurrentOrder";
import OrderDetailsView from "./Pages/Dashboard/Order/OrderDetailsView/OrderDetailsView";
import DeliverdOrder from "./Pages/Dashboard/Order/DeliverdOrder/DeliverdOrder";
import ProductColor from "./Pages/Dashboard/ProductColor/ProductColor";
import ProductSizeQnty from "./Pages/Dashboard/ProductSizeQnty/ProductSizeQnty";
import PrivateRoute from "./Pages/PrivateRoute/PrivateRoute";
import Users from "./Pages/Dashboard/Users/Users";
import UserUpdate from "./Pages/Dashboard/Users/UserUpdate/UserUpdate";
// import TailorMeNow from "./Pages/TailorMeNow/TailorMeNow";
import CustomizeUs from "./Pages/CustomizeUs/CustomizeUs";
import About from "./Pages/About/About";
import ContactUs from "./Pages/ContactUs/ContactUs";
import OfficeLocation from "./Pages/OfficeLocation/OfficeLocation";
import FactoryLocation from "./Pages/FactoryLocation/FactoryLocation";
import StoreLocation from "./Pages/StoreLocation/StoreLocation";
import EliteMembership from "./Pages/EliteMembership/EliteMembership";
import Navigation from "./Pages/Shared/Navigation/Navigation";
import FooterNew from "./Pages/Shared/FooterNew/FooterNew";
import MensCollection from "./Pages/MensCollection/MensCollection";
// import SizeGuide from "./Pages/SizeGuide/SizeGuide";
import Sale from "./Pages/Sale/Sale";
import CustomerService from "./Pages/CustomerService/CustomerService";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import BillingShippingPolicy from "./Pages/BillingShippingPolicy/BillingShippingPolicy";
import ReturnExchangePolicy from "./Pages/ReturnExchangePolicy/ReturnExchangePolicy";
import TrackYourOrder from "./Pages/TrackYourOrder/TrackYourOrder";
import Appointment from "./components/TailorMe/Appointment/Appointment";
import JoinUs from "./Pages/JoinUs/JoinUs";
import TailorMeAll from "./Pages/TailorMeAll/TailorMeAll";
import TailorMeNowDetails from "./Pages/TailorMeNowDetails/TailorMeNowDetails";
import TailorMeNowSize from "./Pages/TailorMeNowDetails/TailorMeNowSize/TailorMeNowSize";
import CustomerInformation from "./Pages/CustomerInformation/CustomerInformation";
import WhatIsTailorMe from "./Pages/WhatIsTailorMe/WhatIsTailorMe";
import WhatIsTailorMeNow from "./Pages/WhatIsTailorMeNow/WhatIsTailorMeNow";
import WhatIsCustomizeUs from "./Pages/WhatIsCustomizeUs/WhatIsCustomizeUs";
import WishListProducts from "./Pages/WishListProducts/WishListProducts";
import AllProducts from "./Pages/Home/AllProducts/AllProducts";

function App() {
  return (
    <div>
      <OnLoadPopUp></OnLoadPopUp>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/productDetails/:productId" element={<ProductDetails />} />
        <Route path="/all-products" element={<AllProducts />} />
        <Route path="/products" element={<ProductContainer />} />
        <Route path="/products/:slug" element={<ProductContainer />} />
        <Route
          path="/discountProducts"
          element={<DiscountProductsContainer />}
        />
        <Route path="/allProductCatagory" element={<AllProductCatagory />} />
        <Route path="/search/:title" element={<SearchShow />} />
        <Route path="/voucher" element={<Voucher />} />
        <Route path="/tailorMeNow" element={<TailorMeNowDetails />}></Route>
        <Route path="/tailorMeNowSize/:tailor_me_now_size_slug" element={<TailorMeNowSize />}></Route>
        <Route path="/tailorMeAll" element={<TailorMeAll />}></Route>
        <Route path="/appointment/:tailor_me_id" element={<Appointment />} />
        <Route path="/customizeUs" element={<CustomizeUs />}></Route>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />

        {/* Footer Links Route Start */}

        <Route path="/aboutUs" element={<About />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/officeLocation" element={<OfficeLocation />} />
        <Route path="/factoryLocation" element={<FactoryLocation />} />
        <Route path="/storeLocation" element={<StoreLocation />} />
        <Route path="/eliteMembership" element={<EliteMembership />} />
        <Route path="/joinUs" element={<JoinUs />} />

        <Route path="/mensCollection" element={<MensCollection />} />
        {/* <Route path="/sizeGuide" element={<SizeGuide />} /> */}
        <Route path="/sale" element={<Sale />} />
        <Route path="/customerService" element={<CustomerService />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route
          path="/billingShippingPolicy"
          element={<BillingShippingPolicy />}
        />
        <Route
          path="/returnExchangePolicy"
          element={<ReturnExchangePolicy />}
        />
        <Route path="/trackOrder" element={<TrackYourOrder />} />
        <Route path="/whatIsTailorMe" element={<WhatIsTailorMe />} />
        <Route path="/whatIsTailorMeNow" element={<WhatIsTailorMeNow />} />
        <Route path="/whatIsCustomizeUs" element={<WhatIsCustomizeUs />} />

        {/* Footer Links Route End */}

        {/* wish list products  */}
        <Route path="/my-wish-list" element={<WishListProducts />} />

        {/* Tailor me now */}
        <Route path="/customerInformation" element={<CustomerInformation />} />

        <Route path="/orderProcess" element={<OrderProcess />}>
          <Route path="checkout" element={<Checkout />} />
          <Route path="payment" element={<Payment />} />
          <Route path="confirmation" element={<Confirmation></Confirmation>} />
        </Route>

        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        >
          <Route path="" element={<WelcomeDzine></WelcomeDzine>}></Route>

          {/* company route */}
          <Route path="company" element={<Company />}></Route>
          <Route path="companyCreate" element={<CompanyCreate />}></Route>
          <Route
            path="companyUpdate/:companyId"
            element={<CompanyUpdate />}
          ></Route>
          <Route
            path="companyDetails/:companyId"
            element={<CompanyDetails />}
          ></Route>

          {/* users list  */}
          <Route path="users" element={<Users />}></Route>
          <Route path="users/:userId" element={<UserUpdate />}></Route>

          {/* promocode route */}
          <Route path="promoCodeCreate" element={<PromoCodeCreate />}></Route>
          <Route path="promoCode" element={<PromoCode />}></Route>
          <Route
            path="promoCodeDetails/:promoId"
            element={<PromoCodeDetails />}
          ></Route>
          <Route
            path="promoCodeUpdate/:promoId"
            element={<PromoCodeUpdate />}
          ></Route>

          {/* product route */}
          <Route path="productCreate" element={<ProductCreate />}></Route>
          <Route path="product" element={<Product />}></Route>
          {/* <Route path="productColor" element={<ProductColor />}></Route>
          <Route path="productSize" element={<ProductSizeQnty />}></Route> */}
          <Route
            path="productDetailss/:productId"
            element={<ProductDetailss />}
          ></Route>
          <Route
            path="productUpdate/:productId"
            element={<ProductUpdate />}
          ></Route>

          {/* catagory route */}
          <Route path="catagory" element={<Catagory />}></Route>
          <Route path="catagoryCreate" element={<CatagoryCreate />}></Route>
          <Route
            path="catagoryDetails/:catId"
            element={<CatagoryDetails />}
          ></Route>
          <Route
            path="catagoryUpdate/:catId"
            element={<CatagoryUpdate />}
          ></Route>

          {/* discount route  */}
          <Route path="discount" element={<Discount />}></Route>
          <Route path="discountCreate" element={<DiscountCreate />}></Route>
          <Route
            path="discountDetails/:discountId"
            element={<DiscountDetails />}
          ></Route>
          <Route
            path="discountUpdate/:discountId"
            element={<DiscountUpdate />}
          ></Route>
          {/* order route  */}
          <Route path="order" element={<CurrentOrder />}></Route>
          <Route path="deliveredOrder" element={<DeliverdOrder />}></Route>
          <Route
            path="orderDetailsView/:orderId"
            element={<OrderDetailsView />}
          ></Route>

          {/* review  */}
          <Route path="review" element={<Review />}></Route>
        </Route>
        <Route path="/dashboard" element={<Dashboard></Dashboard>} />
        {/* <Route path="/dashboardDzine" element={<DashboardDzine></DashboardDzine>} /> */}
      </Routes>
      <FooterNew />
    </div>
  );
}

export default App;
