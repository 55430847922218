import axios from 'axios';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import ProductSizeQnty from '../ProductSizeQnty/ProductSizeQnty';


const ProductColor = ({ productId }) => {

    const [colorId, setColorId] = useState(0);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { register, handleSubmit } = useForm();

    const onSubmit = data => {

        // console.log(productId);
        const formData = new FormData();
        formData.append("product", productId);
        formData.append("title", data?.title);
        formData.append("image", data?.image[0]);

        colorAddHandler(formData);
    };


    const colorAddHandler = (data) => {

        axios.post('https://dzinebackend.techsistltd.com/dashboard/product_color/', data)
            .then(function (response) {
                // console.log(response.data.data);
                if (response.data.status) {
                    setColorId(response.data.data.id)
                    alert(response.data.msg);
                    handleShow();
                }
                else {
                    alert(response.data.msg)
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <>
            <div className='container-fluid py-3 '>

                <div className='  '>
                    <div className="">
                        <form onSubmit={handleSubmit(onSubmit)} >

                            <div className='row  g-3 align-items-center my-2 border p-3 bg-light mx-auto'>
                                <div className="col-6 m-0 ">
                                    <div className="form-floating">
                                        <input {...register("title")} placeholder="Product Title" className="form-control" id="title"
                                            name='title'
                                        // value={pdtColor.title}
                                        // onChange={(e) => handleChangeInput(e, index)}
                                        />
                                        <label htmlFor="Title">Color Title</label>
                                    </div>

                                </div>
                                <div className="col-5 m-0">
                                    <div className="">
                                        <input className='form-control' accept='image' type="file" {...register("image")} />
                                        {/* <label htmlFor="image">Product Image</label> */}
                                    </div>


                                </div>
                                <div className="col-1 m-0">
                                    <input type="submit" value='+' className='btn btn-success' />

                                    {/* <button
                                            type='button'
                                            className='btn btn-danger me-1 fw-bolder'
                                            disabled={productColor.length === 1}
                                            onClick={() => {
                                                remainingField(index);

                                            }}> -</button> */}
                                    {/* <button type='button' className='btn btn-primary fw-bolder m-1'>+</button> */}
                                    {/* <Button variant="primary" onClick={handleShow}>
                                        +
                                    </Button> */}

                                </div>
                            </div>

                        </form>

                    </div>


                </div >

            </div >

            {/* modal  */}
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Size & Quantity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ProductSizeQnty colorId={colorId}></ProductSizeQnty>
                </Modal.Body>

            </Modal>
        </>
    );
};

export default ProductColor;