import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const DiscountUpdate = () => {
    const { discountId } = useParams();
    const [discount, setDiscount] = useState({});
    // console.log(discount)

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const discountUpdateHandler = () => {
        const formData = new FormData();
        formData.append("title", discount?.title)
        formData.append("description", discount?.description)
        formData.append("amount", discount?.amount)
        formData.append("discount_type", discount?.discount_type)
        formData.append("schedule_type", discount?.schedule_type)
        formData.append("start_date", discount?.start_date)
        formData.append("end_date", discount?.end_date)
        formData.append("start_time", discount?.start_time)
        formData.append("closing_time", discount?.closing_time)
        formData.append("image", discount?.image[0])

        axios.patch(`https://dzinebackend.techsistltd.com/dashboard/discount/${discountId}/`, formData)
            .then(function (response) {
                if (response.data.status) {
                    Toast.fire({
                        icon: 'success',
                        title: 'discount Updated!'
                    })
                }
                else {
                    alert(response.data.msg)
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        fetch(`https://dzinebackend.techsistltd.com/dashboard/discount/${discountId}/`)
            .then(res => res.json())
            .then(data => setDiscount(data.data))
    }, [discountId])
    return (
        <div className="container py-3 p-4 shadow ">
            <h2 className='py-3 fw-bolder text-secondary card-title'>Update Your discount</h2>

            <form className="row g-3">
                <div className="col-md-6">
                    <div className="form-floating ">
                        <input type="text" className="form-control" id="title" placeholder="title" required
                            value={discount.title || " "}
                            onChange={(e) => {
                                setDiscount({ ...discount, title: e.target.value })
                            }} />
                        <label htmlFor="title">Title</label>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-floating ">
                        <textarea type="text" className="form-control" id="description" placeholder="description" required
                            value={discount.description || " "}
                            onChange={(e) => {
                                setDiscount({ ...discount, description: e.target.value })
                            }} />
                        <label htmlFor="description">Description</label>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-floating ">
                        <input type="text" className="form-control" id="discount_type" placeholder="discount_type"
                            disabled
                            value={discount.discount_type || " "}
                            onChange={(e) => {
                                setDiscount({ ...discount, discount_type: e.target.value })
                            }} />
                        <label htmlFor="discount_type">Discount Type</label>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-floating ">
                        <input type="text" className="form-control" id="amount" placeholder="amount" required
                            value={discount.amount || " "}
                            onChange={(e) => {
                                setDiscount({ ...discount, amount: e.target.value })
                            }} />
                        <label htmlFor="amount">Amount</label>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-floating ">
                        <input type="text" className="form-control" id="schedule_type" placeholder="schedule_type" disabled
                            value={discount.schedule_type || " "}
                            onChange={(e) => {
                                setDiscount({ ...discount, schedule_type: e.target.value })
                            }} />
                        <label htmlFor="schedule_type">Schedule Type</label>
                    </div>
                </div>
                {
                    discount.schedule_type === "Time_Wise" && <>
                        <div className="col-md-6">
                            <div className="form-floating ">
                                <input type="time" className="form-control" id="start_time" placeholder="start_time" required
                                    value={discount.start_time || " "}
                                    onChange={(e) => {
                                        setDiscount({ ...discount, start_time: e.target.value })
                                    }} />
                                <label htmlFor="start_time">Start Time</label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-floating ">
                                <input type="time" className="form-control" id="closing_time" placeholder="closing_time" required
                                    value={discount.closing_time || " "}
                                    onChange={(e) => {
                                        setDiscount({ ...discount, closing_time: e.target.value })
                                    }} />
                                <label htmlFor="closing_time">Closing Time</label>
                            </div>
                        </div>
                    </>
                }
                {
                    discount.schedule_type === "Date_Wise" && <>
                        <div className="col-md-6">
                            <div className="form-floating ">
                                <input type="date" className="form-control" id="start_date" placeholder="start_date" required
                                    value={discount.start_time || " "}
                                    onChange={(e) => {
                                        setDiscount({ ...discount, start_date: e.target.value })
                                    }} />
                                <label htmlFor="start_date">Start Date</label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-floating ">
                                <input type="date" className="form-control" id="closing_date" placeholder="closing_date" required
                                    value={discount.closing_time || " "}
                                    onChange={(e) => {
                                        setDiscount({ ...discount, end_date: e.target.value })
                                    }} />
                                <label htmlFor="closing_date">Closing Date</label>
                            </div>
                        </div>
                    </>
                }


                <div className="col-md-6">
                    <div className="form-floating ">
                        <input type="file" className="form-control" id="code" placeholder="code" required
                            onChange={(e) => {
                                setDiscount({ ...discount, image: e.target.files })
                            }}
                        />
                        <label htmlFor="companyName">Image</label>
                    </div>
                </div>


                <div className="col-12">
                    <button type="button" className="update-btn" onClick={discountUpdateHandler}>Update</button>
                </div>
            </form>


        </div>
    );
};

export default DiscountUpdate;