import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { RotatingLines } from "react-loader-spinner";
import { Link } from "react-router-dom";
import ProductCard from "../../components/ProductCard/ProductCard";
import TailorMeNow from "./TailorMeNow";
import "./TailorMeNowDetails.css";
const TailorMeNowDetails = () => {
  const [tailorMeNow, setTailorMeNow] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const sortingHandler = (sortingTitle) => {

    setTailorMeNow([]);
    if (sortingTitle === "asc") {
      let low_high_pdt = tailorMeNow.slice().sort((a, b) => a.price - b.price);
      return setTailorMeNow(low_high_pdt);
    }
    else if (sortingTitle === "dsc") {
      let high_low_pdt = tailorMeNow.slice().sort((a, b) => b.price - a.price);
      return setTailorMeNow(high_low_pdt);
    }
    else if (sortingTitle === "all") {

      return setTailorMeNow(tailorMeNow);
    }
  }
  // console.log(deals)
  useEffect(() => {
    setIsLoading(true);
    fetch("https://dzinebackend.techsistltd.com/dashboard/tailor_me_now_product_list/")
      .then((res) => res.json())
      .then((data) => setTailorMeNow(data.data))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <div className="d-flex justify-content-center py-5">
      <RotatingLines
        strokeColor="#d4b16c"
        strokeWidth="3"
        animationDuration="0.75"
        width="50"
        visible={true}
      />
    </div>
  }

  return (
    <Container>
      <div className="row align-items-center pt-3">
        <div className="col-8 col-sm-8 col-md-9 col-lg-10">
          <nav aria-label="breadcrumb ">
            <ol className="breadcrumb products-breadcrumb m-0">
              <li className="breadcrumb-item"><Link to="/home" className='text-decoration-none text-dark'>Home</Link></li>
              <li className="breadcrumb-item"><Link to="/all-products" className='text-decoration-none text-dark'>Products</Link></li>

            </ol>
          </nav>
        </div>

        <div className="col-4 col-sm-4 col-md-3 col-lg-2">
          <select className="sort-by-select " aria-label="Default select example" onChange={(e) => sortingHandler(e.target.value)}>
            <option selected value="all" >Sort by</option>
            <option value="asc">Price: Low to High</option>
            <option value="dsc">Price: High to Low</option>

          </select>
        </div>


      </div>
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-3 py-3">
        {/* {loadingHandle()} */}
        {


          ((tailorMeNow?.length > 0) ? (tailorMeNow?.map(product => <ProductCard
            product={product}
            key={product.id}
          ></ProductCard>)) : <div className="alert alert-warning mx-auto text-center" role="alert">
            Products Not Available!!
          </div>)
        }
      </div>
    </Container>
  );
};

export default TailorMeNowDetails;
