import React, { useState } from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { RiHeartAddFill } from "react-icons/ri";
import "./Service.css";
import axios from 'axios';
// swiper js 
import { Swiper, SwiperSlide } from "swiper/react";
import Swal from 'sweetalert2'
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";
import useCart from '../../Hooks/useCart';
import { Link } from 'react-router-dom';
import ModalPopup from '../TailorMeNowDetails/ModalPopup/ModalPopup';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';


const Service = ({ product }) => {
    // console.log(product)
    // swiper js state

    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    //prduct info distructuring
    const { image_url, title, product_option, price } = product;

    // order quantity
    const [count, setCount] = useState(1);
    const [cartData, setCartData] = useState({ product_size: "", quantity: 1 });

    // cart context api
    const cartInfo = useCart();
    const { items, setItems, orderItems, setOrderItems, customerData, setCustomerData } = cartInfo;
    // console.log(orderItems);

    //error state
    const [error, setError] = useState('');

    // sweetalert toast
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })


    //wishlist handler 
    const wishlistHandler = () => {
        const baseUrl = "https://dzinebackend.techsistltd.com/dashboard/my_wish_list_create/";
        axios.post(baseUrl, { product: product.id })
            .then(function (response) {
                // console.log(response);
                if (response?.data?.status) {
                    localStorage.setItem('slug', JSON.stringify(response?.data?.data?.slug));
                    Toast.fire({
                        icon: 'success',
                        title: 'Product Added in Wishlist!!'
                    })

                } else {
                    alert(response?.data?.msg)
                }


            })
            .catch(function (error) {
                console.log(error);
            });
    }

    // add to cart api 
    const addToCartPdt = () => {

        const baseUrl = "https://dzinebackend.techsistltd.com/dashboard/cart/add/";
        axios.post(baseUrl, cartData)
            .then(function (response) {


                if (response?.data?.data === null) {

                    setError(response?.data?.msg)
                    Swal.fire({
                        html: error,
                        confirmButtonColor: '#d4b16c'

                    }
                    )
                } else {

                    setItems(response?.data?.data);
                    setOrderItems(response?.data?.data?.ordered_items);
                    setCustomerData(response?.data?.data?.customer_info);
                    // console.log(response);
                    Toast.fire({
                        icon: 'success',
                        title: 'Product Added!!'
                    })
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (

        <div className='container-fluid  '>
            <div className='container py-3'>
                <nav aria-label="breadcrumb ">
                    <ol className="breadcrumb products-breadcrumb m-0">
                        <li className="breadcrumb-item"><Link to="/home" className='text-decoration-none text-secondary'>Home</Link></li>
                        <li className="breadcrumb-item"><Link to={`/products/${product?.category_details?.slug}`} className='text-decoration-none text-primary text-secondary'>{product?.category_details?.title}</Link></li>
                        {/* <li className="breadcrumb-item"><Link to="/all-products" className='text-decoration-none text-primary text-secondary'>Products</Link></li> */}
                        <li className="breadcrumb-item active" aria-current="page">{product?.title}</li>
                    </ol>
                </nav>
            </div>
            <div className="container mx-auto">
                <div className="row justify-content-center ">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-5 product-details-img text-center">
                        {/* <img src={image_url} alt="" className='img-fluid p-3' /> */}

                        {/* product image slider */}

                        <div>
                            {product?.image_list?.length === 0 ? <SwiperSlide>
                                <InnerImageZoom src={image_url} zoomSrc={image_url} />

                            </SwiperSlide> : <>
                                <Swiper
                                    style={{
                                        "--swiper-navigation-color": "#000",
                                        "--swiper-pagination-color": "#000",
                                    }}
                                    spaceBetween={10}
                                    navigation={true}
                                    thumbs={{ swiper: thumbsSwiper }}
                                    modules={[FreeMode, Navigation, Thumbs]}
                                    className="mySwiper2"
                                >

                                    {product?.image_list?.map(slider_img => <SwiperSlide key={slider_img?.id}>


                                        <InnerImageZoom src={slider_img?.image_url} zoomSrc={slider_img?.image_url} />
                                        {/* 
                                        

                                        {/* <img src={slider_img?.image_url} alt="" className='img-fluid p-1' /> */}
                                    </SwiperSlide>)}


                                </Swiper>
                                <Swiper
                                    onSwiper={setThumbsSwiper}
                                    spaceBetween={10}
                                    slidesPerView={3}
                                    freeMode={true}
                                    watchSlidesProgress={true}
                                    modules={[FreeMode, Navigation, Thumbs]}
                                    className="mySwiper"
                                >
                                    {product?.image_list?.map(slider_img => <SwiperSlide key={slider_img?.id}>

                                        <img src={slider_img?.image_url} alt="" className='img-fluid p-1' />
                                    </SwiperSlide>)}
                                </Swiper>
                            </>}
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-7 py-3">
                        <h1 className='card-title'>{title}</h1>


                        {!product?.discount_price === null ? < p className=" text-decoration-line-through discount-price-txt">BDT {price}</p> : ""}


                        {product?.discount_price === null ? <h5 className='product-price-card'>BDT {price}</h5> : <h5 className='product-price-card'>BDT {product?.discount_price}</h5>}



                        <div className='py-3'>
                            {/* <p>Choose Model</p> */}
                            <p className='select-size-title me-3'>Select size:</p>

                            <div className='d-flex select-size-btn-container'>
                                {
                                    product_option?.map((pdt_size, index) => {


                                        return (

                                            <div key={pdt_size?.id}>

                                                <ButtonGroup className="d-flex">

                                                    <ToggleButton
                                                        className='me-1 size-toggle-btn'
                                                        key={pdt_size?.id}
                                                        id={`radio-${pdt_size?.id}`}
                                                        type="radio"
                                                        variant="outline-secondary"
                                                        name="radio"
                                                        value={pdt_size?.size_title}
                                                        checked={pdt_size?.id === cartData?.product_size}
                                                        onChange={(e) => {
                                                            setCartData({ ...cartData, product_size: pdt_size?.id })

                                                        }
                                                        }
                                                    >
                                                        {pdt_size.size_title}
                                                    </ToggleButton>
                                                </ButtonGroup>

                                            </div>



                                        )

                                    })
                                }
                            </div>


                        </div>

                        <div className='pb-5'>
                            <p className='select-size-title me-3'>Quantity:</p>
                            <input type="button" value="-"
                                className='incre-decre-btn' disabled={count === 1}
                                style={{ background: count === 1 ? "#333333" : count === 2 ? "#d4b16c" : "#d4b16c" }}
                                onClick={() => {
                                    setCount(count - 1)
                                    setCartData({ ...cartData, quantity: count - 1 })
                                }}
                            />

                            <input type="button" value={count} className=' text-center border-0 bg-light fw-bolder' />

                            <input type="button" value="+" className='incre-decre-btn' onClick={() => {
                                setCount(count + 1)
                                setCartData({ ...cartData, quantity: count + 1 })
                            }} />


                        </div>


                        <div className="">
                            <button className='add-To-Cart-Btn-Card me-2' onClick={() => addToCartPdt()} ><i className="fas fa-cart-plus me-2"  ></i>Add to cart</button>
                            {product?.is_tailor_me && <Link to={`/appointment/${product?.id}`}>
                                < button className='add-To-Cart-Btn-Card me-2' >Tailor Me</button>
                            </Link>}
                            {product?.is_tailor_me_now && <ModalPopup slug={product?.slug}></ModalPopup>}
                            <button type="button" className="  wishlist-btn-icon " onClick={() => wishlistHandler()}>
                                <RiHeartAddFill className="wishlist-d-icon " ></RiHeartAddFill>
                            </button>

                        </div>

                    </div>
                </div>

            </div>

        </div >

    );
};

export default Service;