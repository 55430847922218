import React, { useEffect, useState } from 'react';
import './BannerOffer.css';
import { Carousel } from 'react-bootstrap';
import { RotatingLines } from 'react-loader-spinner';



const BannerOffer = () => {

    const [bannerData, setBannerData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // banner slider api
    useEffect(() => {
        setIsLoading(true);
        fetch("https://dzinebackend.techsistltd.com/dashboard/slider_list/")
            .then(res => res.json())
            .then(data => setBannerData(data.data))
            .finally(() => setIsLoading(false))
    }, [])

    if (isLoading) {
        return <div className="d-flex justify-content-center py-5">
            <RotatingLines
                strokeColor="#d4b16c"
                strokeWidth="3"
                animationDuration="0.75"
                width="50"
                visible={true}
            />
        </div>
    }

    return (
        <div className="container-fluid py-3 px-0 pt-0 banner-first ">

            <div className='container-fluid mx-auto text-center p-0'>

                <Carousel>
                    {bannerData?.map(bannerInfo => <Carousel.Item key={bannerInfo.id}>
                        <img
                            className="d-block w-100"
                            src={bannerInfo.image_url}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            {/* <h3>First slide label</h3>
                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                        </Carousel.Caption>
                    </Carousel.Item>)}

                </Carousel>
            </div>
        </div>
    );
};

export default BannerOffer;