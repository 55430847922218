import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { Link } from "react-router-dom";
import ProductCard from "../../components/ProductCard/ProductCard";
import Footers from "../Shared/Footers/Footers";
import Navigation from "../Shared/Navigation/Navigation";
import DiscountProductCard from "./DiscountProductCard/DiscountProductCard";

const DiscountProductsContainer = () => {
  const [discountProducts, setDiscountProducts] = useState([]);
  // console.log(discountProducts);
  const [isLoading, setIsLoading] = useState(true);
  const [sortingPdt, setSortingPdt] = useState([]);


  const sortingHandler = (sortingTitle) => {

    setSortingPdt([]);
    if (sortingTitle === "asc") {
      let low_high_pdt = sortingPdt.slice().sort((a, b) => a.price - b.price);
      return setSortingPdt(low_high_pdt);
    }
    else if (sortingTitle === "dsc") {
      let high_low_pdt = sortingPdt.slice().sort((a, b) => b.price - a.price);
      return setSortingPdt(high_low_pdt);
    }
    else if (sortingTitle === "all") {

      return setSortingPdt(sortingPdt);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    fetch(
      "https://dzinebackend.techsistltd.com/dashboard/available_discounted_product_list/"
    )
      .then((res) => res.json())
      .then((data) => {
        setDiscountProducts(data.data);
        setSortingPdt(data.data);

      })
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center py-5">
        <RotatingLines
          strokeColor="#d4b16c"
          strokeWidth="3"
          animationDuration="0.75"
          width="50"
          visible={true}
        />
      </div>
    );
  }
  return (
    <>
      {/* <Navigation></Navigation> */}
      <div className="container-fluid p-0">
        <div className="discount-msg">
          <h2 className="text-uppercase">Discount Products !!!</h2>
          {/* <p>15% OFF EVERYTHING + EXTRA $10 OFF FOR PLUS STATUS</p>
          <small>Not a member? Join now to shop.</small> */}
        </div>
        <div className="container py-3">
          <div className="row align-items-center pb-3">
            <div className="col-8 col-sm-8 col-md-9 col-lg-10">
              <nav aria-label="breadcrumb ">
                <ol className="breadcrumb products-breadcrumb m-0">
                  <li className="breadcrumb-item"><Link to="/home" className='text-decoration-none text-dark'>Home</Link></li>
                  <li className="breadcrumb-item"><Link to="/all-products" className='text-decoration-none text-dark'>Products</Link></li>

                </ol>
              </nav>
            </div>

            <div className="col-4 col-sm-4 col-md-3 col-lg-2">
              <select className="sort-by-select " aria-label="Default select example" onChange={(e) => sortingHandler(e.target.value)}>
                <option selected value="all" >Sort by</option>
                <option value="asc">Price: Low to High</option>
                <option value="dsc">Price: High to Low</option>

              </select>
            </div>
          </div>


          <div className="row row-cols-2 row-cols-md-2 row-cols-lg-4 g-4">
            {


              ((sortingPdt?.length > 0) ? (sortingPdt?.map(product => <ProductCard
                product={product}
                key={product.id}
              ></ProductCard>)) : <div className="alert alert-warning mx-auto text-center" role="alert">
                Products Not Available!!
              </div>)
            }
            {/* {sortingPdt?.map((product) => (
              <ProductCard
                product={product} key={product.id}
              ></ProductCard>
            ))} */}
          </div>
        </div>
      </div>
      {/* <Footers></Footers> */}
    </>
  );
};

export default DiscountProductsContainer;
