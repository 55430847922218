import React from 'react';
import { useState } from 'react';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Swal from 'sweetalert2';

const CatagoryCreate = () => {
    const [catagoryImage, setCatagoryImage] = useState("")
    const handleChange = (file) => {
        setCatagoryImage(file[0]);
    }

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const { register, handleSubmit, reset } = useForm();
    const onSubmit = (data) => {
        // console.log(data)

        const formData = new FormData();
        formData.append("title", data?.title);
        formData.append("company", data?.company);

        formData.append("image", data?.image[0]);



        axios.post('https://dzinebackend.techsistltd.com/dashboard/category/', formData)
            .then(res => {
                // console.log(res)
                if (res.data.status) {
                    Toast.fire({
                        icon: 'success',
                        title: 'Product Catagory Added!!'
                    })
                }
                else {
                    alert(res.data.msg)
                }
            })
    }
    return (
        <div className="add-service py-3 ">
            <h2 className='py-3 fw-bolder text-secondary card-title'>Add New Catagory</h2>
            <form className='input-type border shadow  mx-auto p-5 row g-3' onSubmit={handleSubmit(onSubmit)}>

                <div className="col-6">
                    <div className="form-floating">
                        <input className="form-control" id="title" {...register("title")} placeholder="Catagory title" />
                        <label htmlFor="title">Catagory title</label>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-floating">
                        <input className="form-control" id="image" accept='image' type="file" {...register("image", {

                        })} />
                        <label htmlFor="image">Catagory Image</label>
                    </div>
                </div>



                <div className="col-4">
                    <input className='update-btn' type="submit" />
                </div>
            </form>
        </div>
    );
};

export default CatagoryCreate;