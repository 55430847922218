import React from 'react';
import { useState, useEffect } from 'react';
import "./Company.css"
import { Link } from 'react-router-dom';
import { Spinner, Table } from 'react-bootstrap';
import { MdUpdate } from 'react-icons/md';
import { GrView } from 'react-icons/gr';
import useDashboard from '../../../Hooks/useDashboard';
import { RotatingLines } from 'react-loader-spinner';


const Company = () => {

    const [isLoading, setIsLoading] = useState(true);



    // dashboard context api
    const allDashboard = useDashboard();
    const { company, setCompany } = allDashboard;
    // console.log(company)

    useEffect(() => {
        setIsLoading(true)
        fetch("https://dzinebackend.techsistltd.com/dashboard/company_details/")
            .then((res) => res.json())
            .then((data) => setCompany(data.data))
            .finally(() => setIsLoading(false))


    }, [setCompany]);
    // https://dzinebackend.techsistltd.com/dashboard/company/2/

    if (isLoading) {
        return <div className="d-flex justify-content-center py-5">
            <RotatingLines
                strokeColor="#d4b16c"
                strokeWidth="3"
                animationDuration="0.75"
                width="50"
                visible={true}
            />
        </div>
    }

    return (
        <div className='container'>
            <h2 className='py-3 fw-bolder text-secondary card-title'>Company List</h2>
            <div className="row my-5">
                <div className="col">
                    {company === null && <Link to="/dashboard/companyCreate"> <button type="button" className=" create-button">+ Company A Create

                    </button>
                        <br />
                        <br />

                    </Link>}

                    <Table className="table table-bordered bg-light rounded shadow-sm  table-hover table-responsive">

                        <thead className='table-header'>
                            <tr className='text-center'>
                                <th className='py-3' scope="col" width="50">ID</th>
                                <th className='py-3' scope="col">Name</th>
                                <th className='py-3' scope="col">Website</th>
                                <th className='py-3' scope="col">Address</th>
                                <th className='py-3' scope="col">Phone</th>
                                <th className='py-3' scope="col">Action</th>

                            </tr>
                        </thead>

                        <tbody >

                            <tr className='align-items-center'>

                                <td>{company?.id}</td>
                                <td>{company?.name}</td>
                                <td>{company?.website}</td>
                                <td>{company?.address}</td>
                                <td>{company?.phone}</td>
                                <td className=''>
                                    <Link to={`/dashboard/companyDetails/${company?.id}`}>
                                        <GrView className='text-success me-3 fs-5'></GrView>
                                    </Link>
                                    <Link to={`/dashboard/companyUpdate/${company?.id}`}>
                                        <MdUpdate className='fs-4'></MdUpdate>
                                    </Link>
                                </td>


                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    );
};

export default Company;