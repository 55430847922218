import axios from 'axios';
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import useCart from '../../../Hooks/useCart';

const DetailsOrder = () => {

    const [promoTxt, setPromoTxt] = useState('');
    // sweetalert toast
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    // cart context api
    const cartInfo = useCart();
    const { prices, setPrices, items, setItems } = cartInfo;
    // console.log(prices)

    const promoCodeHandler = () => {

        const url = "https://dzinebackend.techsistltd.com/dashboard/applied_promo_code/";
        axios.post(url, {
            order_id: items?.id,
            promo_code: promoTxt
        })
            .then(function (response) {

                if (response?.data?.status) {
                    setItems(response?.data?.data)
                    setPrices(response?.data?.data?.price)

                    Toast.fire({
                        icon: 'success',
                        title: 'Promo Code Added!'
                    })
                }
                else {
                    alert(response?.data?.msg);
                }


            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div className='table-responsive'>
            <table className="table bg-white">
                <thead>
                    <tr>
                        <td className='py-3 p-2 order-process-all-title-txt'>Detail Order</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className='order-process-sub-title-txt'>Product Total Price</td>
                        <td className='order-process-sub-title-txt'>{prices?.product_total_price}</td>
                    </tr>
                    {/* <tr>
                        <td className='fw-bolder order-process-sub-title-txt'>Grand Total</td>
                        <td className='fw-bolder order-process-sub-title-txt'>{prices?.grand_total_price} BDT</td>

                    </tr> */}
                    <tr>
                        <td className='order-process-sub-title-txt'>Discount Amount</td>
                        <td className='order-process-sub-title-txt'>{prices?.discount_amount}</td>
                    </tr>
                    <tr>
                        <td className='order-process-sub-title-txt'>Shipping Cost </td>
                        <td className='order-process-sub-title-txt'>{prices?.delivery_charge}</td>
                    </tr>
                    <tr>
                        <td className='order-process-sub-title-txt'>Packaging</td>
                        <td className='order-process-sub-title-txt'>{prices?.packaging_price}</td>
                    </tr>

                </tbody>

                <tfoot>
                    <tr >
                        <td className='fw-bolder order-process-sub-title-txt'>Payable</td>
                        <td className='fw-bolder order-process-sub-title-txt'>{prices?.payable_amount}</td>
                    </tr>
                </tfoot>

            </table>

            <input type="text" name="" id="" placeholder='Discount Code' className=' promo-input  p-2 ' onChange={(e) => setPromoTxt(e.target.value)} />
            <button className='add-customer-info-btn' onClick={promoCodeHandler}>Apply</button>
        </div>
    );
};

export default DetailsOrder;